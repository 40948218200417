
.breadcrumb {
  border-bottom: $gray-200 1px solid;
  a {
    text-decoration: none;
  }
}

.breadcrumb li:nth-child(2) {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
}

.breadcrumb-root-item {
  ::before {
    content: '§ ';
  }
}


.breadcrumb-subsections {
  list-style: none;
  font-size: $small-font-size;
  margin: 0px;
  padding: 0px;
  margin-left:7rem;
  li {
    padding-bottom:0px;
  }
  .breadcrumb-root-item {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    display: inline-block;
    a {text-decoration: none;}

    ::before {
      display:none;
    }
    &:after {
      content: '/';
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  .breadcrumb-item {
    display: inline-block;
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      a {text-decoration: none;}
      &.active {
        text-transform: none;
        letter-spacing: normal;
        color: $text-muted;
      }
  }


}

.breadcrumb-image {
  position:absolute;
  width: 6rem;
  margin-top: -2rem;
}

.breadcrumb-container {
  background: $lighter;
  box-shadow: $box-shadow-sm;
  margin-left: -0.25rem;
  padding-left: 0.25rem;
}

.breadcrumb-subsection-list{
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding-top: 0.5rem;
}
.breadcrumb-subsection-item {
  text-transform: none;
  letter-spacing: normal;
  display:inline-block;
  margin-right:0.5rem;
  margin-bottom: 0.5rem;
  a {
    display:inline-block;
    background: $primary;
    color: $white;
    padding: 0.5rem;
    text-decoration: none;
    box-shadow: $box-shadow-sm;
    transition: background-color 0.3s ease;
    &:hover, &:focus {
      background: $dark;
      transition: background-color 0.3s ease;
      text-decoration:none;
    }
  }
  &.active {
    display:inline-block;
    background: $more-white;
    color: $dark;
    padding: 0.5rem;
    text-decoration: none;
    box-shadow: $box-shadow-sm;
  }
}
