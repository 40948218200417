
//// BLOCK LIST

.block-list {
  list-style: none;
  padding:0;
  margin:0;
  li {
    padding:0.5em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    border: 1px solid $border-color;
    margin-bottom: 0.3em;
  }
}


// Mixin to allow different colour backgrounds to the block list

@mixin block-list-backgrounds($background, $border ) {
li {
  background-color: $background;
  border-color: $border;
  color: color-yiq($background);
}


}


@each $color, $value in $theme-colors {
  .block-list-#{$color} {
    @include block-list-backgrounds($value,darken($value, 7.5%) );
  }
}

// Circle Numbers

$indicator-size: 3rem;


.list-style-circle-numbers {
  counter-reset: list-counter;
    list-style-type: none;
    position: relative;
    margin-left:0;
    padding-left:0;
  li {
    counter-increment: list-counter;
    min-height: $indicator-size;
    position: relative;
      list-style-type: none;


      padding-left:$indicator-size + $indicator-size * 0.5;
    &:before {
      content: counter(list-counter)".";
      color: $white;
      font-size: 0.8rem;
      font-weight: $font-weight-bold;
      position: absolute;
      left: 0;
      line-height: $indicator-size;
      width: $indicator-size;
      height: $indicator-size;
      top: 0;
      background: $dark;
      text-align: center;
      border-radius:50%;
    }
  }
  
}

@mixin list-style-circle-numbers-colour($color, $name) {
  color: color-yiq($color);
  background: $color;
}

@each $color, $value in $theme-colors {
  li.list-style-circle-numbers-#{$color} {
    &:after {
      @include list-style-circle-numbers-colour($value, $color);
    }
  }
}

$check-size: 1.5em;

.list-style-circle-checks {
  list-style-type: none;
  position: relative;
  margin-left:0;
  padding-left:0;
  margin-left: -1 * ($check-size + $check-size * 0.5);
  li {
    position: relative;
    padding-left:$check-size + $check-size * 0.5;
    min-height: $check-size;
    margin-bottom: 1em;
    padding-top: $check-size * 0.05;
    &:before {
      content:"";
      display: block;
      width: $check-size;
      height: $check-size;
      background: url(#{$image-path}icons/success.svg);
      position: absolute;
      background-size:contain;
      left:0;
      top:0;
      filter: $filter-success-on-dark;
    }
  }
  
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")){
  .list-style-circle-checks {
    margin-left: 0px;
  }
}

// Checks and Lines

$check-size: 1.5em;

.list-checks-lines {
  list-style-type: none;
  position: relative;
  margin-left:0;
  padding-left:0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $border-color;
  li {
    position: relative;
    padding-left:$check-size + $check-size * 0.5;
    padding-top:1rem;
    padding-bottom: 1rem;
    border-top: 1px solid $border-color;
    &:before {

      content:"";
      display: block;
      width: $check-size;
      height: $check-size;
      background: url(#{$image-path}icons/check.svg);
      position: absolute;
      background-size:contain;
      left:0;
      top:1rem;
      filter: $filter-primary;
    }
  }
  
}

// Checks only

$check-size: 1.5em;

.list-checks {
  list-style-type: none;
  position: relative;
  margin-left:0;
  padding-left:0;


  li {
    position: relative;
    padding-left:$check-size + $check-size * 0.5;
    padding-top:1rem;
    padding-bottom: 1rem;

    &:before {

      content:"";
      display: block;
      width: $check-size;
      height: $check-size;
      background: url(#{$image-path}icons/check.svg);
      position: absolute;
      background-size:contain;
      left:0;
      top:1rem;
      filter: $filter-primary;
    }
  }
  
}