// override bootstrap variables
$enable-responsive-font-sizes: true; // let bootstrap do it's 'good enough' thing on bigger and smaller screens
$grid-gutter-width: 16px !default; // reduce grid gutter widths
$container-padding-x: $grid-gutter-width !default; // purely visual tweak to the container and how it relates to the grid
$navbar-padding-x: $grid-gutter-width; // padding for the navbar, in alignment with the container and grid;

$font-family-base: 'Noto Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family:       'Caecilia LT Pro', sans-serif;

// Add additional spacing sizes
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.5),
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 6),
    9: ($spacer * 7.5),
    10: ($spacer * 9),
    "icon": ($spacer * 1.9),
  ),
  $spacers
);


$border-thick: 4px;

$alert-padding-y:                   1rem !default;
$alert-padding-x:                   0rem;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               0px;

$alert-border-width:                $border-thick !default;


$letter-spacing: 0.1em;

$enable-rounded: true;


// BREADCRUMB

$breadcrumb-font-size:              80%;

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     none !default;

$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius: 0;


$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;
$font-size-smallish:          $font-size-base * .9;



$tooltip-max-width: 50rem;
$tooltip-padding-y: 1rem;
$tooltip-padding-x: 1rem;


$grid-gutter-width:           40px;


$box-shadow-sm:               0px 1px 5px rgba(0, 0, 0, 0.1);
$box-shadow:                  0 .5rem 1rem rgba(#000, .15);
$box-shadow-lg:               0 1rem 3rem rgba(#000, .175);


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 900px,
  xl: 1080px,
  xxl: 1240px
) !default;

$yiq-contrasted-threshold: 115;

$image-path: 'images/';

$magnifying-glass: url('../images/icons/search.svg');

$opacity: (
  25: .25,
  50: .5,
  75: .75,
  100: 1
 ) !default;


 // This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
  10: 10%,
  15: 15%,
  25: 25%,
    50: 50%,
    60: 60%,
    75: 75%,
    100: 100%,
    150: 150%,
    auto: auto
  ),
  $sizes
);

$custom-control-indicator-size: 1.5rem;
