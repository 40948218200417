
// Mixin to allow filtering of outline button icons

@mixin button-outline-icon-filter($color, $value) {
img{
  @extend %filter-#{$color} !optional;
  transition: filter 0.3s ease;
}

&:hover {
  img {
    @if color-yiq($value) == $white {
      filter: $filter-white;
    } @else {
      filter: $filter-dark;
    }

    transition: filter 0.3s ease;
  }
}

}


@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-icon-filter($color, $value);
  }
}


// Utility class for button that isn't a button

.not-a-button {
  appearance: none;
  background: none;
  color: inherit;
  border: none;
  padding:0;
  margin:0;
}

.button-wrapper >*{
  margin-top: 1rem;
  &:not(:last-child){
    margin-right: 1rem;
  }
}


// clipboard button styling

.clipboard_btn {
  &:hover, &:focus {
    background: $primary !important;
    cursor: copy;
  }
}


.nav-pills {
  cursor: pointer;
}