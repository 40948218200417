img {
  max-width: 100%;
  height: auto;
}

.ratio16by9 {
  padding-bottom: 56.25%;
  position: relative;
    width: 100%;
    height: 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}

.ratiosquare {
  padding-bottom: 100%;
  position: relative;
    width: 100%;
    height: 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}

.img-contain {
  overflow:hidden;
  img {
    
    object-fit: cover;
  }
}

.rectangle-shadow {
  box-shadow: -2rem 2rem $primary;
  margin-left: 2rem;
  margin-bottom: 4rem;
  figcaption {
    background: $primary;
    margin-right: 2rem;
    padding-top: 0.75rem;
    @if color-yiq($primary) == $white {
      color: $white !important;
    } @else {
      color: $dark !important;
    }
  }
}

.multiply {
  mix-blend-mode: multiply;
}

figure.rounded-circle img {
  border-radius: 50%;
}