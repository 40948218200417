
.circle-blob {
  padding-bottom: 100%;
  border-radius: 50%;
  height: 0;
  position: relative;
  background: transparent;
  
  box-shadow: $box-shadow-sm;
  overflow: hidden;
  display:block;
  z-index: 1;
  &:hover, &:focus {
    .circle-blob-inner:before {
      width: 100%;
      transition: width 0.3s ease;
    }
  }
}

.circle-blob-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding:3rem 3rem 3rem 4.5rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:start;
  div {z-index: 4; position: relative;}
  .arrow {
    align-self:end;
  }
  &:before {
    content: "";
    display: block;
    top: 0px;
    bottom: 0px;
    width: 3rem;
    left:0px;
    position: absolute;
    background: $primary;
    z-index: 3;
    transition: width 0.3s ease;
  }
}

.circle-blob-img {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height: 100%;
  z-index: 2;
}

// Border Variants
@mixin circle-blob-border($border ) {
  .circle-blob-inner:before {background: $border !important;}
  &:hover {
    .circle-blob-inner {
      color: color-yiq($border) !important;
      .arrow {
        @if color-yiq($border) == $white {
          filter: $filter-white !important;
        } @else {
          filter: $filter-dark !important;
        }
      }
    }
  }
}


@each $color, $value in $theme-colors {
  .circle-blob-border-#{$color} {
    @include circle-blob-border($value);
  }
}

// circle blob as checkbox 
.circle-blob:has(:checked) {
  .circle-blob-inner:before {
    width: 100%;
    transition: all 0.3s ease;
    opacity: 50%;
    background: $success !important;
  }
  .circle-blob-inner {
    color: $white !important;
  }
}