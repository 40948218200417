//positioning classes

.position-bottom {
  position:absolute;
  bottom:0;
}

.position-right {
  position:absolute;
  right:0;
}

.position-left {
  position:absolute;
  left:0;
}

.position-top {
  position:absolute;
  top:0;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .position-#{$breakpoint}-top     { top:0; position:absolute; }
    .position-#{$breakpoint}-right   { right:0; position:absolute; }
    .position-#{$breakpoint}-bottom  { bottom:0; position:absolute; }
    .position-#{$breakpoint}-left    { left:0; position:absolute; }
  }
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}

.z-index-1 {
  z-index: 1;
}

// extend sticky top to be responsive
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .sticky-#{$breakpoint}-top {
        @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
      }
    }
  }
}
