// headlines
.headline-circle {
  background: $gray-100;
  border-radius: 50%;
  text-align: center;
  flex: 0 0 auto;
  border: 1px solid $border-color;
  width: 12rem;
  height: 12rem;
  z-index: 2;
  box-shadow: $box-shadow-sm;
}

.headline-circle-content {
  position: absolute;
  width: 12rem;
  height: 12rem;
}

.headline-info {
  flex: 1 1 auto;
  margin-left: -6rem;
  z-index: 1;
  padding-top: 1rem;
}

.headline-yours {
  padding-left:7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  border-radius: 6px;
  box-shadow: $box-shadow-sm;
}

.headline-benchmark {
  padding-left:7rem;
  padding-top: 1rem;
}

.headline-square {
  background: $gray-100;
  text-align: center;
  flex: 0 0 auto;
  border: 1px solid $border-color;
  width: 10rem;
  height: 10rem;
  z-index: 2;
  box-shadow: $box-shadow-sm;
  margin-top:1rem;
  margin-bottom:1rem;
}

.headline-square-content {
  position: absolute;
  width: 10rem;
  height: 10rem;
}
