@mixin filter-variant($parent, $color) {
  #{$parent} {
    filter: $color !important;
  }
}

@each $color, $value in $filter-colors {
  @include filter-variant(".filter-#{$color}", $value);
}


// Utility for filtering an icon according to contrast with background colour

@mixin filter-yiq($value) {

  @if color-yiq($value) == $white {
    filter: $filter-white;
  } @else {
    filter: $filter-dark;
  }

}

@each $color, $value in $theme-colors {
.bg-#{$color} > .filter-yiq {
  @include filter-yiq($value);
}
}


// Classes for dealing with icons that are links

.link-icon {
  filter: $filter-primary;
  transition: filter 0.3s ease;
  &:hover, &:focus {
    filter: $filter-black;
    transition: filter 0.3s ease;
  }
}

a:hover, a:focus {
  .link-icon {
      filter: $filter-black;
      transition: filter 0.3s ease;

  }
}

@mixin link-icon($color, $parent, $value) {

    @extend %filter-#{$color} !optional;
    transition: filter 0.3s ease;

      &:hover, &:focus {
        @if $color == primary-on-dark or $color == warning-on-dark or $color == $white {
          @extend %filter-white !optional;

        } @else {
          @extend %filter-black !optional;

        }
        transition: filter 0.3s ease;
      }
    }

@mixin link-icon-hover($color, $parent, $value) {
  @if $color == primary-on-dark or $color == warning-on-dark or $color == $white {
    @extend %filter-white !optional;

  } @else {
    @extend %filter-black !optional;

  }
  transition: filter 0.3s ease;
}




@each $color, $value in $theme-colors {
  a .link-icon-#{$color}, button .link-icon-#{$color} {
    @include link-icon($color, ".filter-#{$color}", $value);
  }

  a, button {
    &:hover, &:focus {
      .link-icon-#{$color} {
        @include link-icon-hover($color, ".filter-#{$color}", $value);
      }
    }
  }


}

.btn-cancel-edit.filter-warning:hover {
  filter: brightness(0) saturate(100%) !important;
}
