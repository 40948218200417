///// Highcharts variables for overwriting

// Colors for data series and points.
$colors: $chart-series-0 $chart-series-1 $chart-series-2 $secondary $green $red $yellow $pink;

// Chart background, point stroke for markers andc columns etc
$background-color: $white;

// Neutral colors, grayscale by default. The default colors are defined by mixing the
// background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: $black; // Strong text.
$neutral-color-80: $gray-900; // Main text and some strokes.
$neutral-color-60: $gray-800; // Axis labels, axis title, connector fallback.
$neutral-color-40: $gray-700; // Credits text, export menu stroke.
$neutral-color-20: $gray-500; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: $gray-200; // Grid lines etc.
$neutral-color-5: $gray-100; // Minor grid lines etc.
$neutral-color-3: $gray-100; // Tooltip backgroud, button fills, map null points.

// Colored, shades of blue by default
$highlight-color-100: $primary; // Drilldown clickable labels, color axis max color.
$highlight-color-80: mix($primary, $white, 80%); // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: mix($primary, $white, 60%); // Navigator mask fill.
$highlight-color-20: mix($primary, $white, 20%); // Ticks and axis line.
$highlight-color-10: mix($primary, $white, 10%); // Pressed button, color axis min color.

// Fonts
$font-family: $font-family-base;
$title-font-size: 1.5rem !default;
$subtitle-font-size: 1rem !default;
$legend-font-size: $font-size-sm;
$axis-labels-font-size: $font-size-sm;

// Tooltip
$tooltip-border: 1px;
$tooltip-background: $neutral-color-3 !default;

// Axes
$xaxis-grid-line: 0px !default;

// Range-selector
$range-selector-button-border: 0px !default;
$range-selector-input-text: $neutral-color-80 !default;
$range-selector-input-border: $neutral-color-20 !default;

// Data-labels
$data-label-color: $neutral-color-80 !default;

// Buttons
$context-button-background: $background-color !default;

$highcharts-button-background: $neutral-color-3 !default;
$highcharts-button-border: $neutral-color-20 !default;
$highcharts-button-text:  $neutral-color-80 !default;

$highcharts-button-pressed-background: $highlight-color-10 !default;
$highcharts-button-pressed-border: $neutral-color-20 !default;
$highcharts-button-pressed-text:  $neutral-color-80 !default;

$highcharts-button-hover-background: $neutral-color-10 !default;
$highcharts-button-hover-border: $neutral-color-20 !default;
$highcharts-button-hover-text:  $neutral-color-80 !default;

// Navigator
$navigator-series-fill: $highlight-color-80 !default;
$navigator-series-border: $highlight-color-80 !default;

// Scrollbar
$scrollbar-track-background: $neutral-color-5 !default;
$scrollbar-track-border: $neutral-color-5 !default;

// Indicators
$positive-color: $success; // Positive indicator color
$negative-color: $danger; // Negative indicator color


@import "vendor/highcharts";




/* Pattern presentational properties */
#hatch-left path {
	stroke: $chart-series-1;
	stroke-width: 5px;
}

#hatch-right path {
	fill: $white;
	fill-opacity: 0.25;
}

#hatch-right rect {
	stroke: $od-benchmark-primary;
	fill: $od-benchmark-primary;
}

#dots circle {
	fill: $white;
	fill-opacity: 0.35;
}

#dots rect {
	stroke: $od-benchmark-comparison;
	fill: $od-benchmark-comparison;
}

#hatch path {
	fill: $gray-900;
	stroke: $gray-400;
	stroke-width: 1px;
}


.highcharts-series-1 .highcharts-point {
	stroke: $chart-series-1;
	fill: url(#hatch-left);
}
.highcharts-series-2 .highcharts-point {
	stroke: $chart-series-2;
	fill: url(#grid);
}

// line graph options

.highcharts-line-series.highcharts-color-1 .highcharts-point {
	fill: $chart-series-1;
}

.highcharts-line-series.highcharts-color-2 .highcharts-point {
	fill: $chart-series-2;
}

.highcharts-series-1 .highcharts-graph {
	stroke-dasharray: 3;
}

.highcharts-series-2 .highcharts-graph {
	stroke-dasharray: 6;
}

/// Fix rotation of legend pattern for bar graphs
.highcharts-legend .highcharts-bar-series .highcharts-point {
  transform: rotate(90deg) scale(-1, 1) translate(-1.4 * $font-size-base, -1.4 * $font-size-base);
}

.highcharts-legend-item > text {
	font-weight: normal;
}

/// Chart axis $titles
.highcharts-axis-title {
	text-transform: uppercase;
	letter-spacing: $letter-spacing;
	fill: $gray-600;
}

.highcharts-background {
	fill: transparent;
}

.as-tint-v1-segments {
	.highcharts-legend-item.highcharts-color-0 .highcharts-point { // needs extending to all categories if we want to do this
		fill: $as-higher;
		stroke: $as-higher;
	}

	.highcharts-data-label-connector.highcharts-color-0 { // needs extending to all categories if we want to do this
	    stroke: $as-higher;
	}
	.highcharts-series-0 {
		.highcharts-color-0, .highcharts-color-1, .highcharts-color-2 {
			fill: $as-higher;
			stroke: $as-higher;
		}
		.highcharts-color-3, .highcharts-color-4, .highcharts-color-5 {
			fill: $as-medium;
			stroke: $as-medium;
		}
		.highcharts-color-6, .highcharts-color-7, .highcharts-color-8, .highcharts-color-9 {
			fill: $as-lower;
			stroke: $as-lower;
		}
	}
	.highcharts-tooltip {
		stroke: $border-color;
	}
	.highcharts-legend-item.highcharts-color-0 .highcharts-point {
		fill: $gray-900;
		stroke: $gray-900;
	}

}

.as-tint-v1-5-segments {
	.highcharts-legend-item.highcharts-color-0 .highcharts-point {
		fill: $gray-900;
		stroke: $gray-900;
	}
	.highcharts-series-0 {
		.highcharts-color-0, .highcharts-color-1, .highcharts-color-2, .highcharts-color-3, .highcharts-color-4, .highcharts-color-5 {
			fill: $as-higher;
			stroke: $as-higher;
		}
		.highcharts-color-6, .highcharts-color-7, .highcharts-color-8, .highcharts-color-9 {
			fill: $as-medium;
			stroke: $as-medium;
		}
		.highcharts-color-9 { // highcharts gets to 9 and then repeats - using sibling selectors to select the points after 9
			~ .highcharts-color-0, ~ .highcharts-color-1 {
				fill: $as-medium;
				stroke: $as-medium;
			}
			~ .highcharts-color-2, ~ .highcharts-color-3, ~ .highcharts-color-4, ~ .highcharts-color-5, ~ .highcharts-color-6, ~ .highcharts-color-7, ~ .highcharts-color-8, ~ .highcharts-color-9 {
				fill: $as-lower;
				stroke: $as-lower;
			}
		}
	}
	.highcharts-tooltip {
		stroke: $border-color;
	}
}

// stop highcharts menu being cut off on smaller charts
.graph, .highcharts-root, .highcharts-container {
	overflow:visible!important;
    z-index:500!important;
		position:relative;
}

.highcharts-menu {
	z-index:500 !important;
	position:relative;
	list-style-type:none;
}

.highcharts-a11y-proxy-container-after {
	ul {
		list-style-type: none;
	}
}

.highcharts-a11y-proxy-button {
	opacity:0;
}


// Richard (Turney and Hall) Added Styles

	.highcharts-container {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: auto;
	}
	
	.highcharts-axis-line {
		stroke: $gray-200;
	}
	
	.as-higher {
		fill: $as-higher;
		stroke: $as-higher;
	}
	
	.as-medium {
		fill: $as-medium;
		stroke: $as-medium;
	}
	
	.as-lower {
		fill: $as-lower;
		stroke: $as-lower;
	}
	
	.as-higher:hover .aa-xlabel-icon{
		background-color: rgba($as-higher, 0.3);
	}
	
	.as-medium:hover .aa-xlabel-icon {
		background-color: rgba($as-medium, 0.3);
	}
	
	.as-lower:hover .aa-xlabel-icon {
		background-color:rgba($as-lower, 0.3);
	}
	
	.highcharts-tooltip-container {
		z-index: 3000 !important;
	}
	
	.highcharts-tooltip-box {
		fill: $gray-900;
		fill-opacity: 1;
		stroke-width: 0;
	}
	
	.highcharts-tooltip {
		pointer-events: auto;
		text {color:$white; fill:$white;}
	}
	
	
	// XAxis 
	
	.aa-labels {
		z-index: 2000;
	}
	
	.aa-xlabel {
		position: relative; 
		display: flex; 
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
		width:100px;
		.aa-xlabel-title {
			position: relative;
			white-space: normal;
			text-align: right;
			margin-right: 6px;
			min-height: 38px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 14px;
			color: $gray-900;
		}
	}
	
	.aa-xlabel-icon {
		flex-shrink: 0;

		align-items: center;
		justify-content: center;
		width: 34px; 
		height: 34px; 

		border-radius: 50%;
		position: relative;
		display:none;
	}

	@include media-breakpoint-up(sm) {
		.aa-xlabel-icon {
			
			display: flex;
		}
		.aa-xlabel {
			width: 143px;
		}
	}

	@include media-breakpoint-up(lg) {
		.aa-xlabel-icon {
			margin-right: 12px;
		}
	}

	@include media-breakpoint-up(xl) {
		.aa-xlabel-icon {
			margin-right: 0px;
		}
	}
	
	.xaxis-link,
	.xaxis-link:hover,
	.xaxis-link:focus {
		text-decoration: none;
	}
	
	.learn-more {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex; 
		align-items: center;
		justify-content: space-between;
		text-align: right;
		background-color: $gray-900;
		color: $white;
		padding: 0 5px;
		text-decoration: none;
		font-size: 0.8rem;
		transition: 0.3s opacity ease-in-out;
	}
	
	.learn-more::after {
		content: '';
		position: absolute;
		right: 6px;
		width: 12px;
		height: 12px;
		border-top: 1px solid $primary-on-dark;
		border-right: 1px solid $primary-on-dark;
		line-height: 0;
		transform: rotate(45deg);
	}
	
	.xaxis-link:hover .learn-more {
		opacity: 1;
		text-decoration: none;
		transition: 0.3s opacity ease-in-out;
	}
	
	
	


// Tooltip
	
.aatooltip .learn-more,
.aatooltip img {
	display: none;
}

.aatooltip .aa-xlabel {
	justify-content: flex-start;
}

@media (min-width: 768px) {
	.aatooltip-inner {
		width: 400px;
	}
}


.aatooltip > span {
	padding: 8px;
	color: $white;
	z-index: 1001;
	position: relative;

	.aa-xlabel-title {
		display: block;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: left;
		min-height: auto;
		color: $white;
	}

	.aa-xlabel-icon {
		display: none;
	}

	.aatooltip-sub {
		display: block;
		font-style: normal;
		font-weight: 400;
		font-size: 12.8px;
		line-height: 19px;
	}

	.aatooltip-data {
		margin-bottom: 2px;
		font-size: 16px;
		line-height: 24px;
	}

	.aatooltip-info {
		white-space: normal;
		margin: 0;
		font-size: 14.4px;
		line-height: 22px;
	}
}

// .aatooltip > span::before {
// 	content: '';
// 	display: block;
// 	position: absolute;
// 	left: -18px;
// 	top: calc(50% - 10px);
// 	width: 0;
// 	height: 0;
// 	border-style: solid;
// 	border-width: 5px 10px 5px 0;
// 	border-color: transparent $gray-900 transparent transparent; 
// }

.highcharts-legend .highcharts-point {
	stroke-width: 0;
}



#audience-spectrum-bar-chart .highcharts-series-1 .highcharts-point {
	stroke: none;
	fill: url(#hatch);
}

.legend {
	color: $gray-900;
	font-size: 12.8px;
	text-transform: uppercase;
	display: flex;
	margin: 0 70px;
	letter-spacing: $letter-spacing;
}

.legend .label {
	display: flex;
	cursor: pointer;
}

.legend .legend-tab {
	display: block;
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

.legend-tab.as-higher {
	background-color: $as-higher;
}
.legend-tab.as-medium {
	background-color: $as-medium;
}
.legend-tab.as-lower {
	background-color: $as-lower;
}

.legend-tab.aa-col-hatch {
	background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.3) 16.67%, #ffffff 16.67%, #ffffff 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 66.67%, #ffffff 66.67%, #ffffff 100%);
	background-size: 4.24px 4.24px;
}

.legend .legend-title {
	margin: 0 50px 0 3px;
}


// BUBBLE

.aabubble .highcharts-color-1 {
  fill: $as-higher;
  stroke: $as-higher;
}

.aabubble .highcharts-color-2{
  fill: $as-medium;
  stroke: $as-medium;
}

.aabubble .highcharts-color-3 {
  fill: $as-lower;
  stroke: $as-lower;
}

.aabubble .highcharts-data-labels {
  z-index: 500;
  
  img {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .aabubble-inner {
    width: 400px;
    min-height: 142px;
  }
}

.aabubble-tool {
  z-index: 500;
  color: $white;
}

.aabubble-tool > span {
  padding: 8px;
}

.aabubble-title {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  min-height: auto;
  color: $white;
}

.aabubble-data {
  display: block;
  margin: 6px 0;
  font-size: 16px;
  line-height: 24px;
}

.aabubble-info {
  display: block;
  white-space: normal;
  margin: 0;
  font-size: 14.4px;
  line-height: 22px;
}

a.aabubble-link {
  position: relative;
  color: $primary-on-dark;
  text-decoration: none;
  margin: 2px 0;
  font-size: 14.4px;
  line-height: 22px;
  padding-right: 30px;
  display: flex;
  align-items: center;
}

a.aabubble-link::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 1px solid $primary-on-dark;
  border-right: 1px solid $primary-on-dark;
  transform: rotate(45deg);
}


// Donut

.aadoubleDonut-outer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.aadoubleDonut .highcharts-series-0 .highcharts-color-0 {
  fill: $od-your-primary;
  stroke: $od-your-primary;
}

.aadoubleDonut .highcharts-series-1 .highcharts-color-0 {
  fill: $od-your-comparison;
  stroke: $od-your-comparison;
}

.aadoubleDonut .highcharts-series-0 .highcharts-color-1 {
  fill: $gray-100;
  stroke: $gray-100;
}

.aadoubleDonut .highcharts-series-1 .highcharts-color-1 {
  fill: $gray-200;
  stroke: $gray-200;
}

.aadoubleDonut .highcharts-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 120px;
}

.aadoubleDonut .donut-title {
  font-size: 12.8px;
  line-height: 15.36px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
}

.aadonut-tool {
  z-index: 500;
  color: $white;
  display: block;
  font-size: 14.4px;
  line-height: 21.6px;
  text-align: left;
}

.donut-data {
  padding-left: 6px;
  font-size: 14.4px;
  margin-bottom: 6px;
}

.donut-data em {
  font-size: 12.8px;
}

.donut-comparison {
  border-left: 6px solid $od-your-comparison;
}

.donut-comparison .big-number {
  font-size: 20px;
  line-height: 24px;
}

.donut-primary {
  border-left: 6px solid $od-your-primary;
}

.donut-primary .big-number {
  font-size: 32px;
  line-height: 24px;
  font-weight: 700;
}


.variant {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}

.variant-up {
 color: $success;
}

.variant-down {
  color: $danger;
}

.primary-comparison {
	.highcharts-color-0 {
		fill: $od-your-primary;
		stroke: $od-your-primary;
	}
	.highcharts-color-1 {
		fill: $od-your-comparison;
		stroke: $od-your-comparison;
	}
	.highcharts-series-1 .highcharts-point {
		fill: $od-your-comparison;
		stroke: $od-your-comparison;
	}
	.highcharts-color-2 {
		stroke: none;
	fill: url(#hatch);
	}
	.highcharts-series-2 .highcharts-point {
		stroke: none;
	fill: url(#hatch);
	}
}

.benchmark-comparison {
	.highcharts-color-0 {
		fill: url(#hatch-right);
		stroke: $od-benchmark-primary;
	}
	.highcharts-color-1 {
		fill: url(#dots);
		stroke: $od-benchmark-comparison;
	}
	.highcharts-series-1 .highcharts-point {
		fill: url(#dots);
		stroke: $od-benchmark-comparison;
	}
}

.filter {
	.highcharts-color-0 {
		fill: $info;
		stroke: $info;
	}
}

.highcharts-title{
	display:none;
	height:0;
}

// data table

.highcharts-data-table table {
	@extend .table;
	@extend .smallish;
	@extend .table-responsive-sm;
	.highcharts-number {
		text-align: right;
	}
	thead {
		th {
			text-align: right;
		}
		th:first-child {
			text-align: left;
		}
	}
}



// highcharts visually hidden

.highcharts-visually-hidden {

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// classes for Overview Dashboard
.od-bar {
  width:0.75rem !important;
}

// hide square box that's part of the menu
.highcharts-a11y-proxy-element {
	display:none;
}