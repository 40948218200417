/*!
 * OVERRIDES FOR Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

$datepicker-active: $secondary;
$datepicker-active-text: $white;

$datepicker-selected: $gray-700;
$datepicker-selected-text: $white;

$datepicker-standard-hover: $gray-200;

$datepicker-background: $white;

$datepicker-old: $gray-600;

$datepicker-disabled: $gray-100;
$datepicker-disabled-text: $gray-500;

$datepicker-range: mix($datepicker-active, $white, 10%);
$datepicker-range-hover: mix($datepicker-active, $white, 20%);
$datepicker-range-text: $black;

$datepicker-today: $highlight;
$datepicker-today-text: $black;

$datepicker-switch: $primary;
$datepicker-switch-text: $white;

.datepicker {
  border-radius: 4px;
  direction: ltr;
  font-size: $font-size-smallish;
}
.datepicker-inline {
  width: 220px;
}
.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 12px;
}
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $datepicker-background;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15);
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid $datepicker-background;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 4px;
  border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: $datepicker-old;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: $datepicker-standard-hover;
  cursor: pointer;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: $datepicker-disabled;
  color: $datepicker-disabled-text;
  cursor: default;
}
.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0;
}
.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}
.datepicker table tr td.highlighted.focused {
  background: #afd9ee;
}
.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777777;
}
.datepicker table tr td.today {
  color: $datepicker-today-text;
  background-color: $datepicker-today;
  border-color: darken($datepicker-today, 10);
}
.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 5);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.today:hover {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 5);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.today:active,
.datepicker table tr td.today.active {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 5);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 5);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: darken($datepicker-today, 5);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.today.focused {
  background-color: darken($datepicker-today, 5);
}
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background-color: darken($datepicker-today, 5);
  color: $datepicker-disabled-text;
}
.datepicker table tr td.range {
  color: $datepicker-range-text;
  background-color: $datepicker-range;
  border-color: darken($datepicker-range, 10);
  border-radius: 0;
}
.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: $datepicker-range-text;
  background-color: $datepicker-range-hover;
  border-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range:hover {
  color: $datepicker-range-text;
  background-color: $datepicker-range-hover;
  border-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range:active,
.datepicker table tr td.range.active {
  color: $datepicker-range-text;
  background-color: $datepicker-range-hover;
  border-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus {
  color: $datepicker-range-text;
  background-color: $datepicker-range-hover;
  border-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: $datepicker-range;
  border-color: darken($datepicker-range, 10);
}
.datepicker table tr td.range.focused {
  background: $datepicker-range-hover;
}
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: $datepicker-range;
  color: $datepicker-disabled-text;
}
.datepicker table tr td.range.highlighted {
  color: $datepicker-range-text;
  background-color: $datepicker-range-hover;
  border-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: $datepicker-range-text;
  background-color: darken($datepicker-range-hover, 10);
  border-color: darken($datepicker-range-hover, 20);
}
.datepicker table tr td.range.highlighted:hover {
  color: $datepicker-range-text;
  background-color: darken($datepicker-range-hover, 10);
  border-color: darken($datepicker-range-hover, 20);
}
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active {
  color: $datepicker-range-text;
  background-color: darken($datepicker-range-hover, 10);
  border-color: darken($datepicker-range-hover, 20);
}
.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus {
  color: $datepicker-range-text;
  background-color: darken($datepicker-range-hover, 10);
  border-color: darken($datepicker-range-hover, 20);
}
.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: darken($datepicker-range-hover, 10);
  border-color: darken($datepicker-range-hover, 20);
}
.datepicker table tr td.range.highlighted.focused {
  background-color: darken($datepicker-range-hover, 10);
}
.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background-color: darken($datepicker-range-hover, 10);
  color: $datepicker-disabled-text;
}
.datepicker table tr td.range.today {
  color: $datepicker-today-text;
  background-color: $datepicker-today;
  border-color: darken($datepicker-today, 10);
}
.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 10);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.range.today:hover {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 10);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 10);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus {
  color: $datepicker-today-text;
  background-color: darken($datepicker-today, 10);
  border-color: darken($datepicker-today, 20);
}
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: $datepicker-today;
  border-color: darken($datepicker-today, 10);
}
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: $datepicker-today;
  color: $datepicker-disabled-text;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: $datepicker-selected-text;
  background-color: $datepicker-selected;
  border-color: darken($datepicker-selected, 5);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: $datepicker-selected-text;
  background-color: darken($datepicker-selected, 5);
  border-color: darken($datepicker-selected, 10);
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover {
  color: $datepicker-selected-text;
  background-color: darken($datepicker-selected, 10);
  border-color: darken($datepicker-selected, 20);
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active {
  color: $datepicker-selected-text;
  background-color: darken($datepicker-selected, 10);
  border-color: darken($datepicker-selected, 20);
}
.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus {
  color: $datepicker-selected-text;
  background-color: darken($datepicker-selected, 20);
  border-color: darken($datepicker-selected, 30);
}
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  color: $datepicker-selected-text;
  background-color: $datepicker-selected;
  border-color: darken($datepicker-selected, 10);
}
.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: $datepicker-active-text;
  background-color: $datepicker-active;
  border-color: darken($datepicker-active, 10);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  color: $datepicker-active-text;
  background-color: $datepicker-active;
  border-color: darken($datepicker-active, 10);
}
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: $datepicker-active;
  border-color: darken($datepicker-active, 10);
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: $datepicker-standard-hover;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: $datepicker-disabled;
  color: $datepicker-disabled-text;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: $datepicker-active-text;
  background-color: $datepicker-active;
  border-color: darken($datepicker-active, 10);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  color: $datepicker-active-text;
  background-color:$datepicker-active;
  border-color: darken($datepicker-active, 10);
}
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus {
  color: $datepicker-active-text;
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: darken($datepicker-active, 10);
  border-color: darken($datepicker-active, 20);
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: $datepicker-old;
}
.datepicker .datepicker-switch {
  width: 145px;
  color: $datepicker-switch-text;
  background-color: $datepicker-switch;
  font-weight: normal;
  &:hover {
    background-color: darken($datepicker-switch, 10);
  }
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: $datepicker-standard-hover;
}
.datepicker tfoot {
  border:0;
  tr {
    padding-top: 4px;
  }
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
}
.input-daterange input {
  text-align: left;
}
.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}
.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}
