#sign-in {
  background-color: $lighter;
}

@keyframes signin {
  from {background-color: $mid;}
  to {background-color: $lighter;}
}

#sign-in:target {
  animation: fadeInAnimation ease 1s, signin ease 2s;

}