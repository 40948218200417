

/* The container must be positioned relative: */
.fancy-select {
 position: relative;
 border: $border-color 1px solid;
 width:100%;
 min-width: 15rem;
 button {
  position: relative;
 }
}

.fancy-select select {
 display: none; /*hide original SELECT element: */
}

.select-selected {
 background-color: $white;
 width: 100%;
 text-align: left;
}

/* Style the arrow inside the select element, also allows a generic arrow class used in the advanced search button: */
.select-selected:after, .toggle-arrow.collapsed:after {
 position: absolute;
 content: "";
 top: 50%;
 margin-top:-3px;
 right: 0.8em;
 width: 0;
 height: 0;
 border: 6px solid transparent;
 border-color: $body-color transparent transparent transparent;
}

.toggle-arrow { // styling for button with arrow used in the advanced search toggle
  position:relative;
  padding-right: 2em;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after, .toggle-arrow:after {
 position: absolute;
 content: "";
 top: 50%;
 right: 0.8em;
 width: 0;
 height: 0;
 border: 6px solid transparent;
 border-color: transparent transparent $body-color transparent;
 margin-top: -9px;
}

.optgroup {
  position:relative;
}

/* Style the arrow inside the select element: */
.optgroup:after {
 position: absolute;
 content: "";
 top: 14px;
 right: 10px;
 width: 0;
 height: 0;
 border: 6px solid transparent;
 border-color: $body-color transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.optgroup[aria-expanded="true"]:after {
 border-color: transparent transparent $body-color transparent;
 top: 7px;
}



/* style the items (options), including the selected item: */
.select-items div,.select-selected, .search-box, .select-items button {
 color: $body-color;
 padding: 8px 16px;
 border: 1px solid transparent;
 border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
 cursor: pointer;
 width:100%;
 appearance: none;
 text-align:left;
 background-color: $white;
}

/* Style items (options): */
.select-items {
 position: absolute;
 background-color: $white;
 border: $border-color 1px solid;
 box-shadow: $box-shadow-sm;
 top: 100%;
 left: 0;
 right: 0;
 z-index: 99;
 max-height: 70vh;
 overflow-y: auto;
}



/* Hide the items when the select box is closed: */
.select-hide {
 display: none;
}

.select-items div:hover, .select-items button:hover, .same-as-selected { // v light hover state for items within the menu
 background-color: rgba(0, 0, 0, 0.1);
}


#table-container { // position to allow panel to be absolutely positioned
  position: relative;
}

.panel { // right hand panel when productions selected by checkbox
  position: absolute;
  top:0;
  right:0;
  width: 20%;
  min-width: 19rem;
  background: $body-color;
  color:$white;
  height:100%;
  border-bottom: 1px solid transparent;
}

.panel-inner { // sticky inside of right hand panel so that dropdown is always in view if table is long
  position: sticky;
  width:100%;
  top:0;
  padding:1rem;
}

table.dataTable {
  tbody {
    tr.categorised { // sets a green background on categorised productions
      background: rgba($success-on-dark, 0.5);
    }

    tr.excluded { // sets a grey background on excluded productions
      background: $gray-200;
    }
  }
}

.col-artform { // sets the column width of the artform column to ensure the panel aligns
  width:20%;
  min-width: 19rem;
}

// extension of bootstrap nav styles

.nav-simple {
  .nav-link {
    color: $body-color;
    text-decoration: underline;
    text-decoration-thickness: 0.2em;
    text-underline-offset: 0.3em;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease;
    text-decoration-skip-ink: none;
    padding:0.5rem;
  }
  .active, .nav-link:hover {
    text-decoration-color: initial;
    transition: text-decoration-color 0.3s ease;
  }
}



// Multiple productions categorisation 

// Style modal for categorising multiple productions
#multi_select_modal {
  background-color: $gray-900;
  color: $white;
  top: 0;
  right: -4px;
  height: calc(100% - 7.25rem);
  z-index: 100;

  .fancy-select {
    padding: 1rem 0.75rem;
    height: 100%;

    > p,
    > button:not(.close) {
      font-size: $font-size-base;
    }
  }

  .select-items {
    position: relative;
    max-height: 34rem;
  }
}

// Style selected productions
.production_categorisation_controller table.dataTable tbody {
  tr.selected{
    > * {
      box-shadow: none;
      color: $white;
    }

    &.categorised {
      background: $success;
    }
    &.excluded {
      background: $gray-500;
    }
  }
}

// Style checkboxes on productions (some overrides of DataTables module)
table.dataTable {
  thead th {
    vertical-align: middle;
  }
  tbody td.select-checkbox,
  thead th.select-checkbox {
    padding-left: 0.75rem;
    border-left: solid 6px transparent;

    &:before {
      border-radius: 0.25rem;
    }

    &:before,
    &:after {
      top: calc(50% - 4px);
      left: calc(50% + 3px);
      width: 14px;
      height: 14px;
    }
  }
}

table.dataTable tr.selected {
  td, th{
    &.select-checkbox::after {
      margin-top: -5px;
      text-shadow: none;
      font-size: 12px;
    }
  }
}

// Select all rows checkbox
.selectAll {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% + -3px);
  width: 1.25rem;
  height: 1.25rem;
  z-index: 2;
  opacity: 0;
}

table.dataTable thead th.select-checkbox{
  position: relative;
  &::before, 
  &::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
  }

  &::before {
    content: " ";
    margin-top: -2px;
    margin-left: -6px;
    border: 1px solid black;
  }

  &::after {
    top: calc(50% - 10px);
    margin-left: -6px;
    text-align: center;
  }
  
  &[data-selected-state="all"]::after{
    content: "✓";
  }
  &[data-selected-state="some"]::after{
    content: "–";
    top: calc(50% - 11px);
  }
}



// Multiple categorisation success message
.production_categorisation_controller .alert-block + .alert-block {
  margin-top: -1rem;
}

#categorisation-success-message,
#exclusion-success-message {
  display: none; // Don't show success message before needed
}



// bar that indicates what percentage complete prod cat is (width of bar set inline)

.progress-indicator {
  height: 0.8rem;
  width: 100%;
  position: relative;
  background: $gray-200;
  .progress-bar {
    position: absolute;
    background: $body-color;
    top:0;
    height:100%;
    animation: fadeInAnimation ease 3s;
  }
}

// Advanced search colours
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-data {
  border-color: $primary;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-condition {
  border-color: $secondary;
}

div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-value, div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-value {
  border-color: $info;
}

// messing with layout of checkboxes after implementing bootstrap custom checkboxes
table.dataTable.dt-checkboxes-select tbody tr, table.dataTable thead th.dt-checkboxes-select-all, table.dataTable tbody td.dt-checkboxes-cell {
  padding-left:1.5rem;
}

.dataTable {
  .custom-control-label::before, .custom-control-label::after {
    margin-top:2px;
    left:-1rem;
  }
}

// hide checkbox column at small screen size
@include media-breakpoint-down(sm) {
  .datatable .dt-checkboxes-cell {
    display:none;
  }
}
