// Link styles see: http://taadesign.co.uk/components/links

// update link styling

a, .btn-link {
  text-decoration: underline;
  text-decoration-thickness: 0.05em;
  text-underline-offset: 0.25em;
  text-decoration-color: initial;
  transition: color 0.3s ease;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-thickness: 0.3em;
    text-underline-offset: -0em;
    text-decoration-color: $yellow;
    transition: color 0.3s ease;
    text-decoration-skip-ink: none;
  }
}

a.text-decoration-none u {
  text-decoration: underline;
  text-decoration-thickness: 0.05em;
  text-underline-offset: 0.25em;
  text-decoration-color: initial;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-thickness: 0.3em;
    text-underline-offset: -0em;
    text-decoration-color: $yellow;
    text-decoration-skip-ink: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  a, .btn-link {
    text-decoration: none;
  }
}

.typography a {
  font-weight: $font-weight-bold;
  text-decoration-color: rgba($primary-on-dark ,0.2);
  text-decoration-thickness: 0.3em;
  text-underline-offset: 0em;
  text-decoration-skip-ink: none;
  &:hover, &:focus {
    text-decoration: underline;
    text-decoration-thickness: 0.3em;
    text-underline-offset: -0em;
    text-decoration-color: $yellow;
    text-decoration-skip-ink: none;
  }
}

// Change colour of typography links based on background colour 

@mixin link-color-yiq($value) {

  @if $value == $primary {
    a, a:visited {
      color: $white;
      text-decoration-color: rgba($white ,0.2);
      &:hover, &:focus {
        color: $white;
        text-decoration-color: $yellow;
      }
    }
  } @else {
    @if color-yiq($value) == $white {
      a, a:visited {
        color: $primary-on-dark;
        &:hover, &:focus {
          color: $white;
        }
      }
    } @else {
      a, a:visited {
        color: $primary;
        &:hover, &:focus {
          color: $dark;
        }
      }
    }
  }

  

}

.typography {
  @each $color, $value in $theme-colors {
    .bg-#{$color} > .color-yiq,  .bg-#{$color}.color-yiq {
      @include link-color-yiq($value);
    }
  }
}


// Utility class for external link to add icon after
.external-link::after {
  content: ' ';
  width: 14px;
  height: 16px;
  display: inline-block;
  background: url(#{$image-path}icons/external_link.svg) center center no-repeat;
  background-size: contain;
  margin-left: 0.2rem;
  transition: background-image 0.3s ease;
  filter: $filter-primary;
}

.external-link:hover::after {
  transition: filter 0.3s ease;
  filter: $filter-dark;
}


// Utility class for arrow link to add arrow after

.arrow-link::after {
  content: ' ';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(#{$image-path}icons/link_arrow.svg) center center no-repeat;
  background-size: contain;
  margin-left: 0.2rem;
  transition: filter 0.3s ease;
  vertical-align: middle;
  filter: $filter-primary;
}

.arrow-link:hover::after {
  transition: filter 0.3s ease;
  filter: $filter-dark;
}

.arrow-link.text-primary-on-dark::after {
  filter: $filter-primary-on-dark;
}
.arrow-link.text-primary-on-dark:hover::after {
  filter: $filter-white;
}

.arrow-link.text-white::after {
  filter: $filter-white;
}
.arrow-link.text-white:hover::after {
  filter: $filter-highlight;
}

// Utility class for back link to add arrow before

.back-link {
  text-decoration: none;
  font-style: italic;
  @include font-size($font-size-smallish);
  &::before {
    content: ' ';
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(#{$image-path}icons/back_arrow.svg) center center no-repeat;
    background-size: contain;
    margin-right: 0.5rem;
    transition: filter 0.3s ease;
    vertical-align: top;
    filter: $filter-primary;
  }
  &:hover::before {
    transition: filter 0.3s ease;
    filter: $filter-dark;
  }
}


a.text-primary-on-dark {
    &:hover, &:focus {
      color: $white !important;
    }

}

// EDITABLE TEXT

.editable-text {
  background: $black;
  border: 1px solid $black;
  padding: 0.3rem;
  color: $white;
  text-decoration: none;
  border-radius: 0.3em;
  transition: background-color 0.3s ease;
  padding-right:0.3em + 1.5em; // compensates for negative margin to stop icon from wrapping alone
  display: inline-block;
  &:after {
    content: ' ';
    width: 1em;
    height: 1em;
    display: inline-block;
    background: url(#{$image-path}icons/edit.svg) center center no-repeat;
    background-size: contain;
    margin-left: 0.5em;
    vertical-align: middle;
    filter: $filter-white;
    margin-right: -1.5em; // negative margin stops icon from wrapping alone
  }
  &:hover, &:focus {
    background: $highlight;
    border-color: $highlight;
    transition: background-color 0.3s ease;
    text-decoration:none;
    color:$dark;
    &:after {
      filter: $filter-dark;
    }

  }
}

// Mixin to allow different colour backgrounds to the editable-text

@mixin editable-text-backgrounds($background) {
  background-color: $background;
  border-color: $background;
  color: color-yiq($background);
  &:after {
    @if color-yiq($background) == $white {
      filter: $filter-white;
    } @else {
      filter: $filter-dark;
    }

    transition: filter 0.3s ease;
  }
}

@each $color, $value in $theme-colors {
  .editable-text-#{$color} {
    @include editable-text-backgrounds($value);
  }
}




@mixin editable-text-outlines($background, $border, $color ) {
  background-color: $background;
  border: $border 1px solid;
  color: $border;
  @extend %filter-#{$color} !optional;
  &:after {

    transition: filter 0.3s ease;
  }
  &:hover {
    &:after {filter: $filter-dark;}
    filter:none;
    background: $highlight;
    border-color: $highlight;
    color:$dark;
    transition: filter 0.3s ease;
  }
}

@each $color, $value in $theme-colors {
  .editable-text-outline-#{$color} {
    @include editable-text-outlines(transparent,$value, $color);
  }
}

// Mixin to allow different colour backgrounds to the editable-text

@mixin editable-text-backgrounds($background) {
  background-color: $background;
  border-color: $background;
  color: color-yiq($background);
  &:after {
    @if color-yiq($background) == $white {
      filter: $filter-white;
    } @else {
      filter: $filter-dark;
    }

    transition: filter 0.3s ease;
  }
}

@each $color, $value in $theme-colors {
  .editable-text-#{$color} {
    @include editable-text-backgrounds($value);
  }
}




@mixin editable-text-outlines($background, $border, $color ) {
  background-color: $background;
  border: $border 1px solid;
  color: $border;
  @extend %filter-#{$color} !optional;
  &:after {

    transition: filter 0.3s ease;
  }
  &:hover {
    &:after {filter: $filter-dark;}
    filter:none;
    background: $highlight;
    border-color: $highlight;
    color:$dark;
    transition: filter 0.3s ease;
  }
}

@each $color, $value in $theme-colors {
  .editable-text-outline-#{$color} {
    @include editable-text-outlines(transparent,$value, $color);
  }
}








.previous-link {
  color: $black;
  line-height: 2;
  text-decoration: none;
  font-style: italic;
  display: inline-block;


  &:before {
    content: ' ';
    width: 2em;
    height: 2em;
    display: inline-block;
    background: url(#{$image-path}icons/previous.svg) center center no-repeat;
    background-size: contain;
    margin-right: 0.5rem;
    transition: filter 0.3s ease;
    vertical-align: middle;
    filter: $filter-black;
  }
}

.next-link {
  color: $black;
  line-height: 2;
  text-decoration: none;
  font-style: italic;
  display: flex;


  &:after {
    content: ' ';
    width: 2em;
    height: 2em;
    display: inline-block;
    background: url(#{$image-path}icons/next.svg) center center no-repeat;
    background-size: contain;
    margin-left: 0.5rem;
    transition: filter 0.3s ease;
    vertical-align: middle;
    filter: $filter-black;
  }
}

.previous-link, .next-link {
  &:hover, &:focus {
    color: $primary;
    text-decoration: none;
    &:before, &:after {
      filter: $filter-primary;
      transition: filter 0.3s ease;
    }
  }
}

// block arrow link
.block-arrow-link {
    display:inline-block;
    background-color: $body-color;
    color: $white;
    padding: 0.8em;
    text-decoration:none;
    border: 1px solid $body-color;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    padding-right:0.8em + 1.5em; // compensates for negative margin to stop icon from wrapping alone
    &::after {
      content: " ";
      width: 1em;
      height: 1em;
      display: inline-block;
      background: url(#{$image-path}icons/link_arrow.svg) center center no-repeat;
      background-size: contain;
      margin-left: 0.5em;
      transition: filter 0.3s ease;
      vertical-align: middle;
      filter: $filter-primary-on-dark;
      margin-right: -1.5em; // negative margin stops icon from wrapping alone
    }
    &:hover, &:focus {
      text-decoration: none !important;
      background: $yellow !important;
      border-color: $yellow !important;
      color: $body-color !important;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
      &:after {
        transition: filter 0.3s ease;
        filter: $filter-dark;
      }
    }


}
a:hover, a:focus {
  .block-arrow-link {
    text-decoration: none;
      background: $yellow;
      border-color: $yellow;
      color: $body-color;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
      &:after {
        transition: filter 0.3s ease;
        filter: $filter-dark;
      }
  }
}

// Mixin to allow different colour backgrounds to the block-arrow-link

@mixin block-arrow-link-backgrounds($background, $border, $color ) {
  background-color: $background;
  border-color: $border;
  color: color-yiq($background) !important;
  &:after {
    @if $color == 'light' {
      filter: $filter-primary;
    } @else {
      @if color-yiq($background) == $white {
        filter: $filter-white;
      } @else {
        filter: $filter-dark;
      }
    }

    transition: filter 0.3s ease;
  }
  &:hover, &:focus {
    color: $body-color !important;
    &:after {
      filter: $filter-dark;
    }
  }
}


@each $color, $value in $theme-colors {
  .typography .block-arrow-link-#{$color}, .block-arrow-link-#{$color} {
    @include block-arrow-link-backgrounds($value,darken($value, 7.5%), $color );
  }
}

.blocky-links{
  line-height:1.8;
  a, a:visited {
    background: rgba($primary-on-dark, 0.5);
    transition: background-color 0.3s ease;
    padding: 0.1em 0.2em;
    color:$body-color;
    font-weight: $font-weight-bold;
    box-decoration-break: clone;
    text-decoration: none;
    &:hover, &:focus {
      background: $primary-on-dark;
      transition: background-color 0.3s ease;
    }
  }
}


// general mixin for coloured backgrounds within links to darken on hover

@mixin bg-hover($parent, $color) {
  #{$parent} {
    background-color: darken($color, 6%) !important;
    transition: background-color 0.3s ease;
  }
}

.area-popup-segment-list [data-segment-load]:hover {
  .btn-as-higher {
    background-color: #8f2f17;
  }
  .btn-as-medium {
    background-color: #9e9521;
  }
  .btn-as-lower {
    background-color: #216e75;
  }
}

@mixin bg-transition($parent, $color) {
  #{$parent} {
    transition: background-color 0.3s ease;
  }
}

a, a:visited {
  @each $color, $value in $theme-colors {
    @include bg-transition(".bg-#{$color}", $value);
  }
  &:hover, &:focus {
    @each $color, $value in $theme-colors {
      @include bg-hover(".bg-#{$color}", $value);
    }
  }
}

a[class*="bg-"] {
  transition: background-color 0.3s ease;
  &:hover, &:focus {
    transition: background-color 0.3s ease;
  }
}


// Mixin to allow different colour backgrounds to the block-arrow-link

@mixin underline-color($color ) {
  text-decoration-color: $color;
}


@each $color, $value in $theme-colors {
  .underline-#{$color} {
    @include underline-color($value);
  }
}

.text-decoration-underline {
  text-decoration-line: underline;
}

// IMG LINK

a:hover .img-link {

  img {
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
  }
}

.img-link {
  background: $primary;
  img {
    transition: opacity 0.3s ease-in-out;
  }
}


a:hover, a:focus {
  .move-right {
    transform: translateX(0.5em);
    transition: transform 0.3s ease;
  }
}

a, a:visited {
  .move-right {
    transition: transform 0.3s ease;
  }
}

a:hover, a:focus {
  .primary-hover, &.primary-hover {
    color: $primary !important;
    transition: color 0.3s ease;
  }
  
}

a, a:visited {
  .primary-hover, &.primary-hover {
    transition: color 0.3s ease;
  }
}