.side-nav {
  position: sticky;
  top: 0;
  .active {
    a, a:visited {
      background: rgba($gray-200, 50%);
      img {
        @extend %filter-primary-on-dark;
      }
    }
  }
  ul {
    margin-bottom:0;
  }
  a, a:visited {
    display:block;
    border-top: 1px solid $border-color;
    text-align: center;
    padding: 1.5rem 0.2rem;
    text-decoration: none;
    font-size: $font-size-smallish;
    font-weight: $font-weight-bold;
    color: $text-muted;
    transition: all 0.3s ease;
    img {
      @extend %filter-mid;
    }
    &:hover, &:focus {
      background: $gray-200;
      transition: all 0.3s ease;
      img {
        @extend %filter-primary-on-dark;
      }
    }
    span {
      display:block;
      margin-top: 0.2rem;
      line-height: 1.1;
      padding-top: 0.2rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }

  li:last-child {
    a, a:visited {
      border-bottom: 1px solid $border-color;
    }
  }
}



@media screen and (max-width: map-get($grid-breakpoints, "lg")){
  .side-nav {
    position:relative;
    top:0;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      li {
        break-inside: avoid-column;
        flex-grow:1;
        flex:1;
        display:flex;
        align-items: stretch;
        a, a:visited {
          border: none !important;
          flex-grow:1;
        }
      }
    }
  }
}
