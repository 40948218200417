/// TAB NAV

.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
}

.tab-nav {
  display: flex;
  align-items: stretch;
}

.current-tab-item {
  border-top: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  border-radius: 6px;
  a, a:visited {
    display: inline-block;
    border-top: 6px solid $as;
    border-right: 6px solid $as;
    border-left: 6px solid $as;
    padding:1rem;
    padding-bottom: calc(1rem + 6px);
    background: $white;
    z-index: 100;
    position:relative;
    border-radius: 6px;
    img {
      height: 3vw;
      min-height: 1.7rem;
      max-height:3rem;
    }
  }
}



.tab-nav-item {
  display:inline-flex;
  align-items: end;
  margin-left:1rem;
  border-top: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  border-radius: 3px;
  
  a, a:visited {
    border-top: 6px solid $light;
    border-right: 6px solid $light;
    border-left: 6px solid $light;
    border-image:none;
    border-radius: 3px;
    padding:0.5rem;
    padding-bottom: calc(0.5rem + 6px);
    background: $light;
    z-index: 0;
    position:relative;
    img {
      height: 2vw;
      min-height: 1.5rem;
      max-height: 2rem;
    }
    &:hover {
      z-index: 100;
      background: $white;
      border-width: 6px;
    }
  }
}

#astab:hover {
  border-color: $as;
}

#taatab:hover {
  border-color: $taa;
}

#aatab:hover {
  border-color: $aa;
}

#searchtab:hover {
  border-color: transparent;
  border-image: conic-gradient(from 0deg, $taa, $as, $aa, $taa) 1;
}

@media screen and (max-width: 799px) {
  #aatab picture {
    filter: $filter-aa;
  }
  #taatab picture {
    filter: $filter-taa;
  }
  #astab picture {
    filter: $filter-as;
  }
}



.tab-page {
  border-top: 6px solid $as;
  border-image: none;
  background: $white;
  margin-top: -6.5px;
  z-index: 50;
  position:relative;
  width:100%;
}

.aa {
  .current-tab-item {
    a, a:visited {
      border-color: $aa;
    }
  }
  .tab-page {
    border-color: $aa;
  }
}

.taa {
  .current-tab-item {
    a, a:visited {
      border-color: $taa;
    }
  }
  .tab-page {
    border-color: $taa;
  }
}

.search {
  .current-tab-item {
    a, a:visited {
      border-color: transparent;
      border-image: conic-gradient(from 0deg, $taa, $as, $aa, $taa) 1;
    }
  }
  .tab-page {
    border-color: transparent;
    border-image: linear-gradient(90deg, $taa, $as, $aa) 1;
  }
}

@media screen and (min-width: 501px) {
  body:has(#aatab:hover) .tab-page {
    border-top: 6px solid $aa;
  }
  
  body:has(#taatab:hover) .tab-page {
    border-top: 6px solid $taa;
  }

  body:has(#astab:hover) .tab-page {
    border-top: 6px solid $as;
  }
  
  body:has(#searchtab:hover) .tab-page {
    border-top: 6px solid transparent;
    border-image: linear-gradient(90deg, $taa, $as, $aa) 1;
  }
  
  body:has(#taatab:hover),  body:has(#aatab:hover),  body:has(#searchtab:hover), body:has(#astab:hover) {
    .current-tab-item {
      a, a:visited {
        border-color: $light;
        background: $light;
        z-index: 0;
      }
    }
  }
}



@media screen and (max-width: 500px) {
  .tabs-container {
    flex-direction: column-reverse;
    margin-left:-21px;
    margin-right: -21px;
    overflow-x: hidden;
  }
  .current-tab-item {
    background: $white;
    align-items: stretch;
    display: flex;
    justify-content: start;
    border-top: 6px solid $as;
    border-radius: 0px;
    margin-top: -6px;
    z-index: 100;
    a, a:visited {
      padding-left:20px;
      border:none;
    }
  }
  .aa .current-tab-item {
    border-color: $aa;
  }
  .taa .current-tab-item {
    border-color: $taa;
  }
  .tab-nav {
    justify-content: end;
    padding-right: 27px;
  }
  .tab-page {
    border-top: 6px solid $white;
  }
  body:has(#aatab:hover) .current-tab-item {
    border-top: 6px solid $aa;
  }
  
  body:has(#taatab:hover) .current-tab-item {
    border-top: 6px solid $taa;

  }

  body:has(#astab:hover) .current-tab-item {
    border-top: 6px solid $as;

  }
  
  body:has(#searchtab:hover) .current-tab-item {
    border-top: 6px solid transparent;
    border-image: linear-gradient(90deg, $taa, $as, $aa) 1;

  }

  .navbar {
    margin-top:0 !important;
  }
}