// Adds responsive utility rotate classes to change the orientation of elements at certain breakpoints e.g. rotate-90 at all breakpoints or rotate-md-180 for middle and up breakpoints

// This variable affects the `.rotate-*` and `.rotate-*` classes.
$rotations: () !default;
$rotations: map-merge(
  (
    90: 90deg,
    180: 180deg,
    0: 0deg
  ),
  $rotations
);


  @each $rotation, $degrees in $rotations {
    .rotate-#{$rotation} { transform: rotate($degrees) !important; }
  }


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);


    @each $rotation, $degrees in $rotations {
      .rotate#{$infix}-#{$rotation} { transform: rotate($degrees) !important; }
    }
  }

}