.theme-item {
    background-color: $gray-100;
    color: $gray-900;
}

.drag-grip {
    left: -1.5rem;
    opacity: 0;
}

.drag-grip--parent:hover .drag-grip,
.drag-grip--parent:focus .drag-grip {
    opacity: 1;
}

.status-marker--active {
    background-color: $topaz;
}

.status-marker--inactive {
    background-color: $gray-500;
}
.status-marker--draft {
    background-color: $orange;
}

.status-marker-after {
    display: inline-block;
    padding-right: 2rem;
}

.status-marker-after--active {
    background: right / 20px no-repeat url( #{$image-path}icons/circle--success.svg);
}

.status-marker-after--inactive {
    background: right / 20px no-repeat url( #{$image-path}icons/circle--inactive.svg);
}

.status-marker-after--draft {
    background: right / 20px no-repeat url( #{$image-path}icons/circle--warning.svg);
}


.help-aside {
    @include media-breakpoint-up(lg) {
        position: sticky;
        top: 3rem;
        margin-left: 1rem;
        width: 100%;
    }
}


// Form element adjustments

.questions_controller, .survey_templates_controller {

    .col-form-label-sm {
        font-size: 0.9rem;
    }

    .form-check-label-sm {
        font-size: 0.9rem;
    }

    .custom-control-input,
    .custom-control-label::before,
    .custom-control-label::after {
        width: 1.5rem;
        height: 1.5rem;
    }

    .custom-control-label::before {
        border: $gray-500 solid 3px;
    }

    .custom-control-label {
        margin-left: 0.75rem;
    }

    .custom-control-label::before, .custom-control-label::after {
        top: 33%;
        left: -2.25rem;
    }
}

.answer-delete.btn {
    min-height: 2.3rem;
    align-self: flex-end;
    align-items: center;
    &.btn-outline-danger {
        cursor: unset;
    }
    &.btn-sm{
        line-height: 1.75;
    }

    .custom-control{
        display: flex;
        padding-right: 1.5rem;
        padding-left: 0;
    }
    .custom-control-input {
        z-index: 5;
        width: calc(100% + 3rem);
        height: calc(100% + 0.75rem);
        left: -2.25rem;
        top: -0.4rem;
        cursor: pointer;
        &:checked ~ .custom-control-label::before {
            border-color: $warning;
            background-color: $warning;
        }
    }
    .custom-control-label::before,
    .custom-control-label::after {
        width: 1rem;
        height: 1rem;
        top: 50%;
        left: unset;
        right: -1.5rem;
    }
    &:hover .custom-control-input:checked ~ .custom-control-label::before{
        border-color: $danger-on-dark;
        background-color: $danger-on-dark;
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: $danger;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(129, 29, 29, 0.25);
    }
}
