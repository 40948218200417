.data-marker {
  display:inline-block;
  padding:0.8rem;
  background: $dark;
  font-size: $small-font-size;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
  border-radius: 1rem;
  vertical-align: middle;
  color: $white;
  &:before {
    width:1.5rem;
    height: 1.5rem;
    content: " ";
    display:inline-block;
    background: url(#{$image-path}icons/ticketing.svg) center center no-repeat;
    background-size: contain;
    margin-right: 0.5rem;
    vertical-align: middle;
    @extend %filter-white;
  }
}

.data-marker-ticketing {
  background-color: $ticketing;
  &:before {
    background-image: url(#{$image-path}icons/ticketing.svg);
  }
}

.data-marker-survey {
  background-color: $surveys;
  &:before {
    background-image: url(#{$image-path}icons/surveys.svg);
  }
}
