@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .border-#{$breakpoint}         { border: $border-width solid $border-color !important; }
    .border-#{$breakpoint}-top     { border-top: $border-width solid $border-color !important; }
    .border-#{$breakpoint}-right   { border-right: $border-width solid $border-color !important; }
    .border-#{$breakpoint}-bottom  { border-bottom: $border-width solid $border-color !important; }
    .border-#{$breakpoint}-left    { border-left: $border-width solid $border-color !important; }
    .border-#{$breakpoint}-left-0  {
        border-left:none !important;
    }
    .border-#{$breakpoint}-right-0 {
        border-right:none !important;
    }
    .border-#{$breakpoint}-top-0 {
        border-top:none !important;
    }
    .border-#{$breakpoint}-bottom-0 {
        border-bottom:none !important;
    }
  }
}

// Thick border left
@mixin border-left-thick($border ) {
  border-left: 0.8rem $border solid !important;
}


@each $color, $value in $theme-colors {
  .border-left-thick-#{$color} {
    @include border-left-thick($value);
  }
}

// Thick border bottom
@mixin border-bottom-thick($border ) {
  border-bottom: 0.8rem $border solid !important;
}


@each $color, $value in $theme-colors {
  .border-bottom-thick-#{$color} {
    @include border-bottom-thick($value);
  }
}

.border-thick {
  border-width: $border-thick !important;
}



.very-rounded {
  border-radius: 18px;
}


.fancy-border-hover {
  &:hover {
    border-image: linear-gradient(var(--angle), $as-higher, $as-medium, $as-lower, $info) 1;
  
    animation: 10s rotate linear infinite;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}