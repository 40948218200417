.breadcrumbs-as.breadcrumb-container {
  background: $dark;
  a {
    color: $primary-on-dark;
  }
  .breadcrumb-subsections {
    margin-left: 0;
  }
  .breadcrumb-root-item:after {
    color: $light;
  }
  .breadcrumb-subsection-item {
    a {
      background: none;
      padding: none;
      box-shadow: none;
      font-size: 0.9rem;
    }
  }
  .breadcrumb-item.active {
    color: $light;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: $light;
  }
}