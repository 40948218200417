.circle {
  border-radius: 50%;
  height: 0px;
  padding-bottom: 100%;
  position: relative;
}

.circle-inner {
  position: absolute;
  width:100%;
  height:100%;
}

@media screen and (max-width: map-get($grid-breakpoints, "md")){
  .circle {
    border-radius: 10px;
    height: auto;
    padding-bottom: 0;
  }
  .circle-inner {
    position: relative;
  }
}
