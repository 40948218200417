// faux tablet border for previews
.tablet {
  padding: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  position: relative;
  background: $black;
  border-radius: 2em;
  div:first-child {
    border-radius: 2px;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")){
  .tablet {
    border-radius: 1em;
  }
}