.delete-fade-animation {
  animation: fade-out forwards 0.5s ease-out 1;
}

.create-fade-animation {
  animation: fade-in forwards 0.5s ease-out 1;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
