.logo-grid {
  img {
    filter: saturate(0%);
    opacity:0.5;
}
a:hover img {
    filter: saturate(100%);
    opacity:1;
}

}