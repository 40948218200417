
.h1, h1 {
  line-height: 1.1; // tighten up the leading on h1
}

.font-base {
  font-family: $font-family-base;

}

.font-headings {
  font-family: $headings-font-family;
}

.lead {
  // tighten up lead line-height
  line-height: 1.5;
  font-weight: inherit;
}


// Add letter spacing utility class
.letter-spaced {
  letter-spacing: $letter-spacing;
}


// Add bigger line height utility class for things with heading classes

.line-height-bigger {
  line-height: 1.2;
}

.line-height-biggest {
  line-height: 1.7;
}


// custom 'overhang' negative margin for headings with icons

@include media-breakpoint-up(lg) {
  .overhang {
    margin-left: -1 * (48px + 16px);
  }
  .overhang-smaller {
    margin-left: -0.5em;
  }
}




// Utility class for smallish but not quite small


.smallish {
  @include font-size($font-size-smallish);
  font-weight: $font-weight-normal;
}

// Utility class for p-size text

.body {
  @include font-size($font-size-base);
}

// Overhanding lists class for larger breakpoints
@include media-breakpoint-up(lg) {
  .list-overhanging {
    list-style-position: outside;
    padding-left: 0px;
  }
}



// utility to override font-italic
.font-normal {
  font-style: normal !important;
}




// Typographic styles for long form text
.typography {
  h2 {
    @extend .mt-6;
    @extend .mb-4;
  }
  &> h2:first-child,
  &> [data-flexible-content-type]:first-child > h2:first-child,
  div:not([data-flexible-content-type]) > h2:first-child {
    margin-top:0 !important;
  }
  h3 {
    @extend .h4;
    @extend .mt-5;
    @extend .mb-3;
  }
  &> h3:first-child,
  &> [data-flexible-content-type]:first-child > h3:first-child,
  div:not([data-flexible-content-type]) > h3:first-child  {
    margin-top:0 !important;
  }
  h4 {
    @extend .h5;
    @extend .mt-5;
    @extend .mb-3;
    font-weight: bold;
  }
  &> h4:first-child,
  &> [data-flexible-content-type]:first-child > h4:first-child,
  div:not([data-flexible-content-type]) > h4:first-child {
    margin-top:0 !important;
  }
  .badge + h2, .badge + h3, .badge + h4 {
    margin-top:0 !important;
  }
  &.short {
    h2 {
      @extend .h4;
      @extend .mt-5;
      @extend .mb-3;
    }
    h3 {
      @extend .h5;
      @extend .mt-5;
      @extend .mb-3;
      font-weight: bold;
    }
  }
  li {
    @extend .mb-2;
  }
  ol, ul {
    @extend .mb-4;
  }
  .h1 { @include font-size($h1-font-size); }
  .h2 { @include font-size($h2-font-size); }
  .h3 { @include font-size($h3-font-size); }
  .h4 { @include font-size($h4-font-size); }
  .h5 { @include font-size($h5-font-size); }
  .h6 { @include font-size($h6-font-size); }

  .blockquote {
    @extend .mt-6;
    @extend .mb-5;
  }
  &> .blockquote:first-child,
  &> [data-flexible-content-type]:first-child > .blockquote:first-child,
  div:not([data-flexible-content-type]) > .blockquote:first-child {
    margin-top:0 !important;
  }

  &> .box:first-child,
  &> [data-flexible-content-type]:first-child > .box:first-child,
  div:not([data-flexible-content-type]) > .box:first-child {
    margin-top:0 !important;
  }

  // hsmall reassert
  .hsmall {
    @extend .small;
    @extend .font-base;
    @extend .letter-spaced;
    @extend .text-uppercase;
    @extend .mb-3;
  }
  // reassert the margin utility classes within typography
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
          .#{$abbrev}t#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-top: $length !important;
          }
          .#{$abbrev}r#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: $length !important;
          }
          .#{$abbrev}b#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-bottom: $length !important;
          }
          .#{$abbrev}l#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-left: $length !important;
          }
        }
      }
  
      // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
      @each $size, $length in $spacers {
        @if $size != 0 {
          .m#{$infix}-n#{$size} { margin: -$length !important; }
          .mt#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-top: -$length !important;
          }
          .mr#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-right: -$length !important;
          }
          .mb#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-bottom: -$length !important;
          }
          .ml#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-left: -$length !important;
          }
        }
      }
    }
  }
  .p-mb-0 {
    p {margin-bottom: 0 !important;}
  }
}

.p-mb-0 {
  p {margin-bottom: 0 !important;}
}

.blockquote {
  padding-left: 1rem;
  border-left: 6px solid $primary;
  position:relative;
  margin-left:3.5rem;
  @include font-size(1.1rem);
  &:before {
    content: "\201C";
    top:0;
    left:-4rem;
    position: absolute;
    color: $text-muted;
    font-size: 6rem;
    line-height:1;
    font-family: $headings-font-family;
  }
  .blockquote-footer {
    color: $text-muted;
    font-style: italic;
  }
}

// pullquote 

.pullquote {
  border-top: 6px solid $primary;
  padding-top: 2rem;
  border-bottom: 6px solid $primary;
  padding-bottom: 1rem;
  @extend .h3;
  color: $text-muted;
  font-style: italic;
}

// ellipsis

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


//result highlighting for Search

.result {
  background: rgba($highlight, 0.8);
  color: $body-color;
}

.bg-dark .text-muted {
  color: $text-muted-on-dark !important;
}

[data-flexible-content-type="content_block_box"] {
  color: $white;
  .bg-light {
    color: $gray-900;
  }
}


.huge {
  @include font-size(8rem);
  font-family: $headings-font-family;
  line-height: 1;
}

.big-intro {
  @include font-size(2.375rem);
  font-style:italic;
  font-family: $headings-font-family;
  line-height: 1.3;
  em {
    font-style: normal;
  }
}

.big-home-intro {
  @include font-size(2.875rem);
  font-family: $headings-font-family;
  line-height: 1.2;
}

.bigger {
  @include font-size(3.5rem);
}


.balanced {
  text-wrap:balance;
}

hr.short-primary {
  border-color: $primary;
  border-width: 6px;
  width: 20%;
  margin-left: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

// hsmall 
.hsmall {
  @extend .small;
  @extend .font-base;
  @extend .letter-spaced;
  @extend .text-uppercase;
}