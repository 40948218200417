.speech {
  background: $dark;
  color: $white;
  padding: 1rem;
  position:relative;
  margin-left: 1.5em;
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    margin-top:  - 0.75em;
    left: -1.5em; // offset should move with padding of parent
    border: .75rem solid transparent;
    border-right-color: $dark;
  }
}

.speech-bottom {
  background: $dark;
  color: $white;
  padding: 1rem;
  position:relative;
  margin-bottom: 1.5em;
  box-shadow: $box-shadow;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 10%;
    bottom: -1.5em; // offset should move with padding of parent
    border: .75rem transparent solid;
    border-top-color: $dark;
  }
}

@mixin speech-color($color) {
  background-color: $color;
  border-color: darken($color, 7.5%);
  color: color-yiq($color);
  &:after {
    border-top-color: $color;
  }
  &:before {
    border-right-color: $color;
  }
}

@each $color, $value in $theme-colors {
  .speech-#{$color} {
    @include speech-color($value);
  }
}

.speech-mark {
  &:before {
    content: '\275D';
    font-size: 5rem;
    position: absolute;
    top:0;
    left:-3.2rem;
    line-height:1;
    text-shadow: $box-shadow;
  }
}


@mixin speech-mark-color($color) {
  &:before {
    color: $color;
  }
}

@each $color, $value in $theme-colors {
  .speech-mark-#{$color} {
    @include speech-mark-color($value);
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")){
  .speech-mark,
  .speech-mark + footer {
    margin-left: 3.5rem;
  }
}
