.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    opacity:20%;
  }
  100% {
    opacity: 100%;
  }
}