.segment-blob-link {
  position:relative;
  text-decoration: none;
  width:100%;
  display:block;
  
  &:hover {
    text-decoration: none;
  }
  .segment-icon {
    display: block;
    width:3.5rem;
    height:3.5rem;
    border-radius: 50%;
    padding:1rem;
    position:relative; 
    z-index: 2;
  }
  .segment-title {
    padding-left: 2.5rem;
    @extend .py-2;
    @extend .pr-2;
    display:block;
    @extend .rounded;
    z-index: 1;
    margin-left: 1rem;
    position:relative;
    margin-top:-2.9rem;
    display: block;
    width:auto;
    
  }
  
}

.bg-as-subsegment-higher {
  background: linear-gradient(to top left, $as-higher 40%, #ef7d39 40%);
}

.bg-as-subsegment-medium {
  background: linear-gradient(to top left, $as-medium 40%, #d2cb76 40%);
}

.bg-as-subsegment-lower {
  background: linear-gradient(to top left, $as-lower 40%, #79b2b7 40%);
}
