.map-grid #map {
  padding: 0;
}

#map-inner {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (min-width: 1200px) {
  .map-grid {
    [data-segment], #all-segments {
      flex: 0 0 32%;
      max-width: 34%;
    }

    #map {
      position: relative;
      flex: 1 1 68%;
      max-width: 68%;
    }
  }
}

.key-frame {
  width: 23px;
  padding-left: 23px;
}

.intensity-zero {
  opacity: 0.2;
}
.intensity-twenty {
  opacity: 0.4;
}
.intensity-forty {
  opacity: 0.6;
}
.intensity-sixty {
  opacity: 0.8;
}
.intensity-full {
  opacity: 1;
}

.segment-icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  padding: 1rem;
  margin-left: -0.75rem;

  &.active {
    background-color: white !important;
    &.btn-as-higher img {
      filter: $filter-as-higher;
    }
    &.btn-as-medium img {
      filter: $filter-as-medium;
    }
    &.btn-as-lower img {
      filter: $filter-as-lower;
    }
  }

    &.btn-as-higher img {
      filter: $filter-white;
    }
    &.btn-as-medium img {
      filter: $filter-dark;
    }
    &.btn-as-lower img {
      filter: $filter-white;
    }

}


.segment-icon--lg {
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  margin-top: 3rem;
}
.segment__title {
  margin-top: 2.7rem;
}

.segment__keys {
  max-height: 10rem;
  margin-top: 1rem;
}
.segment__keys li {
  flex: 0 0 50%;
}

.home-heritage-segment__keys li span {
  background-color: #a09722;
}

#map {
  min-height: 180px;
  @media screen and (max-width: 768px) {
    height: calc(100vh - 100px);
  }
}

.map-nav li a {
  font-size: 0.9rem;
}

#map-nav-condensed {
  padding-top: 4.45rem;
  flex: 0 0 80px;
  max-width: 80px;
}

.map-panel {
  flex: 1 0 calc(100% - 80px);
  max-width: 100%;
}

[data-subsegment] {
  [data-sub-segment-load] {
    display: block !important;
  }
  [data-sub-segment-unload] {
    display: none !important;
  }
}

[data-subsegment].active {
  [data-sub-segment-load] {
    display: none !important;
  }
  [data-sub-segment-unload] {
    display: block !important;
    color: #b03a1c !important;
  }
}

.mapboxgl-popup-close-button {
  right: .5rem !important;
  top: .5rem !important;
  font-size: 3rem;
  font-family: serif;
  font-family: serif;
  padding: .25rem;
}

.mapboxgl-popup-content {
  width: 350px;
  padding: 1.25rem 1.25rem 1.25rem !important;
  border-radius: 10px !important;
}

.zoom_true .mapboxgl-popup-content {
  padding-bottom: 2.25rem !important;
}

.mapboxgl-popup-content h4 {
  font-size: $font-size-base;
  margin: 0.75em 0 0.25em;

  &.text-uppercase {
    font-size: 0.9rem;
  }
}



.area-popup-segment__area {
  line-height: 1;
  font-size: 1.5rem;
}

.area-popup-segment-list > li:first-child {
  font-size: 1.25em;
}

.area-popup-segment-list > li:first-child .segment-breakdown__name {
  padding-left: 3.25rem;
  padding-right: 0.5em;
}

.area-popup-segment-list .segment-icon {
  margin-left: -0.5rem;
}

.area-popup-segment-list-breakdown {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 2rem;
}

.zoom_true .area-popup-segment-list-breakdown {
  margin-bottom: 2rem;
}

.zoom_false .area-popup-segment-list {
  margin-bottom: 0;
}

.area-popup-segment-list-breakdown .segment-breakdown__percent {
  flex: 0 0 1.5rem;
  text-align: right;
}

.zoom-into-area {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  left: 0;
  background-color: $teal;
  color: white;
  border: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
}

.mapboxgl-popup-anchor-bottom .zoom-into-area::after {
    content: "";
    border: 11px solid transparent;
    height: 0;
    width: 0;
    z-index: 1;
    align-self: center;
    border-bottom: none;
    border-top-color: $teal;
    position: absolute;
    bottom: -10px;
}

.map-interface--top-left {
  z-index: 5;
  pointer-events: none;
  > * {
    pointer-events: initial;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);

  }
}

.map__metadata {
  max-width: 275px;
  h4, p {
    font-size: 0.83rem;
  }
}

.reset-map {
  width: max-content;
  padding: 0.5rem 0.65rem;
  font-size: 0.9rem;
  border: none;
  align-items: center;
}

.mapboxgl-control-container .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 1.5rem 10px 0 0;
  }
}

.segment-circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.segment-circle {
  img {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
  }
  .subsegment-name {
    position: absolute;
    bottom: 18px;
    right: 18px;
    font-size: 0.8rem;
    font-weight: bold;
  }
}


.mapboxgl-ctrl-top-right {
  display: flex;
  align-items: center;
}

.sub-bar {
  height: 1.5rem;
  &:not(:last-of-type){
    border-right: 2px solid white;
  }
  &:first-of-type{
    opacity: 0.75;
  }
}

.subsegments-percentages {
	gap: 1.5em;
  font-size: 0.9rem;
	justify-content: space-between;
}

.mapboxgl-popup {
  z-index: 10;
}


@media screen and (max-width: 768px) {
  .mapboxgl-ctrl-top-right {
    display: flex;
    flex-direction: row-reverse;
    left: 0;
    right: unset;
  }

  .map-interface--top-left {
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }

  .reset-map {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 540px) {
  .mapboxgl-popup-content {
    width: 310px;
    padding: 0.5rem 0.5rem 1rem 1rem !important;
  }
}

.segment-household-count {
  font-size: 0.9rem;
}


.no-pointer {
  pointer-events: none;
}
