// print styles specifically for printing reports
@media print {
  .page {
    box-shadow: none !important;
    border-top: none !important;
  }
  .print-break-inside-avoid {
    page-break-inside: avoid;
    position:relative;
  }
  .print-break-before-always {
    page-break-before: always;
    position:relative;
  }
  .print-break-after-avoid {
    page-break-after: avoid;
    position:relative;
  }
  .print-border {
    border: 1px solid $border;
  }
  .standard-wrapper {
    width: auto !important;
    padding: 0 !important;
    position:relative;
  }
  .graph {
    position:relative;
    display:block;
  }
  table {
    width:100%;
  }
  body.reports_controller {
    background: #fff;
    color: $dark !important;

    #demographic-disability-social, #demographic-gender, #ticketing-spectrum,
    section {
      page-break-after: always;
      margin-top: 0 !important;
    }

    h3 {
      background: $gray-100 !important;
      color: $dark !important;
      padding: 10px !important;
    }

    .card {
      background: #fff;
      border: none;
      box-shadow: none;
      padding: 0;
      page-break-inside: avoid;
    }

    .alert {
      background: #fff;
      color: $dark !important;
      page-break-inside: auto !important;

      a {
        background: #fff;
        color: $dark !important;
        text-decoration: none;
      }
    }
  }
}

@page {
    size: auto;
    width:100%;
    .graph {
      height:100%;
    }
}
