
@each $prop, $value in ($opacity) {
  .opacity-#{$prop} {
    opacity: $value;
  } 
}

.inactive {
  opacity: 30%;
}

@each $color, $hex in $theme-colors {
  @each $prop, $value in ($opacity) {
    .bg-#{$color}-opacity-#{$prop} {
      background-color: rgba($hex, $value);
    } 
  }
}