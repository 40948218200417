// background for fancy Audience Spectrum promo
.as-bg {
  background-image: url(#{$image-path}backgrounds/audience-spectrum-background.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 35% auto;
}

.bg-img-as {
  background-image: url(#{$image-path}backgrounds/as-background.svg);
  background-repeat: no-repeat;
  background-position: 120% 40%;
  background-size: auto 300%;
}

// background image login
.background-image-login {
  background: url(#{$image-path}backgrounds/login-background-answers.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

// background-blur
.bg-dark-blur {
  background: rgba(23, 23, 23, 0.8);
  backdrop-filter: blur(6px);
}

//Overview Dashboard pattern backgrounds
.bg-od-benchmark-primary {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M0 20L20 0H10L0 10M20 40V10L10 20'/%3E%3C/g%3E%3C/svg%3E");
}

.bg-od-benchmark-comparison {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.35' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 60%;
}

.bg-od-catchment {
  background: $gray-400 url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 20L20 0H10L0 10M20 40V10L10 20'/%3E%3C/g%3E%3C/svg%3E") top right repeat;
  background-size: 25%;
}

// Promo backgrounds 

.bg-img-promo-profile {
  background: url(#{$image-path}backgrounds/profile-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.bg-img-promo-place {
  background: url(#{$image-path}backgrounds/place-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.bg-img-promo-behaviour {
  background: url(#{$image-path}backgrounds/behaviour-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.half-circle {
  background:url(#{$image-path}backgrounds/half-circle.svg) top center no-repeat;
  background-size: 100% auto;
  position: relative;
}

.half-circle-primary {
  background:url(#{$image-path}backgrounds/half-circle-primary.svg) top center no-repeat;
  background-size: 100% auto;
  position: relative;
}

.bg-img-overview-dashboard {
  background:url(#{$image-path}backgrounds/overview-dashboard.png) top right no-repeat;
  background-size: 25% auto;
}

.bg-img-survey-report {
  background:url(#{$image-path}backgrounds/survey-report.png) top right no-repeat;
  background-size: 25% auto;
}

.bg-img-evidence {
  background:url(#{$image-path}backgrounds/evidence.png) bottom right no-repeat;
  background-size: 30% auto;
}

@media screen and (max-width: map-get($grid-breakpoints, "xl")){
  .bg-img-overview-dashboard, .bg-img-survey-report, .bg-img-evidence {
    background-size: 20% auto;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")){
  .bg-img-overview-dashboard, .bg-img-survey-report, .bg-img-evidence {
    background: none;
  }
}

.bg-yield {
  background:url(#{$image-path}backgrounds/yield-background.svg) 20px bottom no-repeat;
  background-size: auto 80% ;
}

// Utility for changing text color according to contrast with background colour

@mixin text-color-yiq($value) {

  @if color-yiq($value) == $white {
    color: $white !important;
  } @else {
    color: $dark !important;
  }

}

@each $color, $value in $theme-colors {
  .bg-#{$color} > .color-yiq,  .bg-#{$color}.color-yiq {
    @include text-color-yiq($value);
  }
}


.bg-img-diagonal_stripes {
  position:relative;
  overflow:hidden;
  &:before {    
    content: "";
    background-image: url(#{$image-path}patterns/diagonal_stripes.png);
    background-size: 60%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
}

.bg-img-polka_dots {
  position:relative;
  overflow:hidden;
  &:before {    
    content: "";
    background-image: url(#{$image-path}patterns/polka_dots.png);
    background-size: 60%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
}

.bg-img-zig_zag {
  position:relative;
  overflow:hidden;
  &:before {    
    content: "";
    background-image: url(#{$image-path}patterns/zig_zag.png);
    background-size: 90%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
}

// Promo backgrounds 

.bg-img-promo-profile {
  background: url(#{$image-path}backgrounds/profile-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.bg-img-promo-place {
  background: url(#{$image-path}backgrounds/place-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.bg-img-promo-behaviour {
  background: url(#{$image-path}backgrounds/behaviour-background.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}



// background image login
.background-image-login {
  background: url(#{$image-path}backgrounds/login-background-answers.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

// background new login
.bg-new-login {
  background: url(#{$image-path}backgrounds/login-background-new.png) center center no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

// background-blur
.bg-dark-blur {
  background: rgba(23, 23, 23, 0.8);
  backdrop-filter: blur(6px);
}

@keyframes bg-transition-danger-darker {
  from {background-color: $primary;}
  to {background-color: $danger-darker;}
}


.bg-transition-danger-darker {
  animation: bg-transition-danger-darker ease 2s;
  background-color: $danger-darker;
}

@keyframes bg-transition-success {
  from {background-color: $primary;}
  to {background-color: $success;}
}


.bg-transition-success {
  animation: bg-transition-success ease 2s;
  background-color: $success;
}


.bg-cpm {
  background-image: url(#{$image-path}backgrounds/cpm-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}



@media screen and (min-width: map-get($grid-breakpoints, "md")){
  .bg-md-right {
    background-repeat:no-repeat;
    background-position: top right;
    background-size: auto 100%;
  }

}

@media screen and (max-width: map-get($grid-breakpoints, "md")){
  .bg-sm-none {
    background-image: none !important;
  }
}
