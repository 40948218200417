.report {
  padding-top: 120%;
  height: 0;
  position: relative;
  background: $more-white;
  border-left: 1rem solid $primary;
  box-shadow: $box-shadow-sm;
  overflow: hidden;
}

.report-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding:1.5rem 1.5rem 1.5rem 1rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:start;
  .block-arrow-link {
    align-self:end;
  }
}

.report-img {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height: 100%;
}


// Border Variants
@mixin report-border($border ) {
  border-left-color: $border !important;
}


@each $color, $value in $theme-colors {
  .report-border-#{$color} {
    @include report-border($value);
  }
}

// Background Variants
@mixin report-bg($background ) {
  background-color: $background !important;
  color: color-yiq($background);
}


@each $color, $value in $theme-colors {
  .report-bg-#{$color} {
    @include report-bg($value);
  }
}