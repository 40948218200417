// Colours

$green:     #0E5D4D;
$raspberry: #D60B52;
$cyan:      #2DB8C5;
$teal:      #1D7981;
$purple:    #932A6E;
$blue:      #636AAF;
$yellow:    #F2E61A;
$orange:    #CC4214;
$peach:     #EE764F;
$red:       #B03A1C;
$topaz:     #19BC9C;
$pink:      #F08597;
$aubergine: #703157;
$bright-orange: #E94E1B;
$dark-raspberry: #9A083B;

// Shades

$cyan-light: #D1ECEE; 

// Audience Spectrum Tints

$as-higher: #B03A1C;
$as-medium: #BEB328;
$as-lower:  #257B83;


// Grayscale

$more-white: #FCFCFB;
$white:     #FAF9F8;
$gray-50:   #F5F4F3;
$gray-100:  #F2F0EE;
$gray-200:  #D9D7D5;
$gray-300:  #C1C0BE;
$gray-400:  #A8A7A6;
$gray-500:  #91908E;
$gray-600:  #787776;
$gray-700:  #60605F;
$gray-800:  #484746;
$gray-900:  #30302F;
$black:     #171717;

// Site theming
$as: $blue;
$aa: $cyan;
$taa: $raspberry;
$search: $orange;

// Colour usage

$more-white:      $more-white !default;
$primary:         $teal !default;
$secondary:       $purple !default;
$brand:           $cyan !default;
$brand-light:     $cyan-light !default;
$highlight:       $yellow !default;
$primary-on-dark: $cyan !default;
$success:         $green !default;
$success-on-dark: $topaz !default;
$info:            $blue !default;
$warning:         $orange !default;
$warning-on-dark: $peach !default;
$danger:          $raspberry !default;
$danger-darker:   $dark-raspberry !default;
$danger-on-dark:  $pink !default;
$lighter:         $gray-50 !default;
$light:           $gray-100 !default;
$dark:            $gray-900 !default;
$mid:             $gray-300 !default;
$black:           $black !default;
$tips:            $gray-700 !default;
$border:          $gray-200 !default;
$muted:          $gray-700 !default;
$ticketing:       $primary !default;
$surveys:         $secondary !default;
$wip:             $primary !default;

// Overview dashboard
$od-your-primary:           $brand !default;
$od-your-comparison:          $primary !default;
$od-benchmark-primary:      $gray-400 !default;
$od-benchmark-comparison:     $gray-600 !default;



$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "brand":      $brand,
    "brand-light": $brand-light,
    "highlight":  $highlight,
    "success":    $success,
    "success-on-dark" : $success-on-dark,
    "info":       $info,
    "warning":    $warning,
    "warning-on-dark" : $warning-on-dark,
    "danger":     $danger,
    "danger-darker": $danger-darker,
    "danger-on-dark" : $danger-on-dark,
    "lighter":      $lighter,
    "light":      $light,
    "dark":       $dark,
    "mid":        $mid,
    "border":     $border,
    "muted":      $muted,
    "primary-on-dark": $primary-on-dark,
    "black":      $black,
    "white":      $white,
    "as-higher":  $as-higher,
    "as-medium":  $as-medium,
    "as-lower":   $as-lower,
    "tips":       $tips,
    "more-white": $more-white,
    "aubergine":  $aubergine,
    "orange":     $bright-orange,
    "od-your-primary": $od-your-primary,
    "od-your-comparison": $od-your-comparison,
    "od-benchmark-primary": $od-benchmark-primary,
    "od-benchmark-comparison": $od-benchmark-comparison,
    "wip": $wip,
    "as": $as,
    "aa": $aa,
    "taa": $taa,
    "search": $search
  ),
  $theme-colors
);


$body-bg:                           $gray-100;
$body-color:                        $gray-900;

$text-muted:                        $gray-700;
$text-muted-on-dark:                $gray-200;

$link-hover-color:                  $body-color;
$link-decoration:                   underline;

$link-on-dark-color:                $cyan;

$navbar-dark-color:                 $white;
$navbar-light-color:                $primary;

$input-group-addon-bg:              $gray-900;
$input-group-addon-color:           $white;
$input-group-addon-border-color:    $black;

$input-color:                           $black !default;
$input-border-color:                    $gray-400 !default;

$input-focus-bg:                        lighten($brand, 50%) !default;
$input-focus-border-color:              lighten($brand, 25%) !default;

$border-color:                      $gray-200;

$hr-border-color:                   $gray-200;


// filters for SVG - created using https://codepen.io/sosuke/pen/Pjoqqp

$filter-primary:          brightness(0) saturate(100%) invert(34%) sepia(93%) saturate(344%) hue-rotate(137deg) brightness(99%) contrast(94%) !default;
$filter-secondary:        brightness(0) saturate(100%) invert(21%) sepia(31%) saturate(4548%) hue-rotate(296deg) brightness(87%) contrast(87%) !default;
$filter-primary-on-dark:  brightness(0) saturate(100%) invert(81%) sepia(9%) saturate(3730%) hue-rotate(143deg) brightness(81%) contrast(89%) !default;
$filter-success:          brightness(0) saturate(100%) invert(27%) sepia(59%) saturate(551%) hue-rotate(118deg) brightness(93%) contrast(95%) !default;
$filter-success-on-dark:  brightness(0) saturate(100%) invert(55%) sepia(71%) saturate(455%) hue-rotate(118deg) brightness(94%) contrast(95%) !default;
$filter-info:             brightness(0) saturate(100%) invert(40%) sepia(44%) saturate(544%) hue-rotate(197deg) brightness(97%) contrast(92%) !default;
$filter-warning:          brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3062%) hue-rotate(357deg) brightness(92%) contrast(88%) !default;
$filter-warning-on-dark:  brightness(0) saturate(100%) invert(62%) sepia(46%) saturate(2545%) hue-rotate(327deg) brightness(97%) contrast(93%) !default;
$filter-danger:           brightness(0) saturate(100%) invert(14%) sepia(62%) saturate(7422%) hue-rotate(332deg) brightness(85%) contrast(96%) !default;
$filter-danger-on-dark:   brightness(0) saturate(100%) invert(75%) sepia(20%) saturate(3461%) hue-rotate(301deg) brightness(102%) contrast(88%) !default;
$filter-light:            brightness(0) saturate(100%) invert(99%) sepia(80%) saturate(521%) hue-rotate(291deg) brightness(110%) contrast(89%) !default;
$filter-dark:             brightness(0) saturate(100%) invert(16%) sepia(8%) saturate(72%) hue-rotate(22deg) brightness(100%) contrast(93%) !default;
$filter-mid:              brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(282%) hue-rotate(314deg) brightness(86%) contrast(71%) !default;
$filter-white:            brightness(0) saturate(100%) invert(98%) sepia(6%) saturate(611%) hue-rotate(306deg) brightness(105%) contrast(96%) !default;
$filter-black:            brightness(0) saturate(100%) !default;
$filter-highlight:        brightness(0) saturate(100%) invert(90%) sepia(43%) saturate(1168%) hue-rotate(344deg) brightness(104%) contrast(90%) !default;
$filter-tips:            brightness(0) saturate(100%) invert(36%) sepia(6%) saturate(86%) hue-rotate(22deg) brightness(96%) contrast(82%) !default;
$filter-border:           brightness(0) saturate(100%) invert(83%) sepia(2%) saturate(214%) hue-rotate(349deg) brightness(105%) contrast(93%) !default;
$filter-od-your-primary: $filter-primary-on-dark;
$filter-od-your-comparison: $filter-primary;
$filter-brand: $filter-primary-on-dark;
$filter-wip:              $filter-primary;
$filter-as-higher:        brightness(0) saturate(100%) invert(28%) sepia(25%) saturate(5152%) hue-rotate(351deg) brightness(85%) contrast(89%) !default;
$filter-as-medium:        brightness(0) saturate(100%) invert(66%) sepia(60%) saturate(539%) hue-rotate(16deg) brightness(96%) contrast(85%) !default;
$filter-as-lower:         brightness(0) saturate(100%) invert(41%) sepia(99%) saturate(306%) hue-rotate(137deg) brightness(92%) contrast(91%) !default;
$filter-aa:               brightness(0) saturate(100%) invert(81%) sepia(9%) saturate(3730%) hue-rotate(143deg) brightness(81%) contrast(89%) !default;
$filter-taa:              brightness(0) saturate(100%) invert(14%) sepia(62%) saturate(7422%) hue-rotate(332deg) brightness(85%) contrast(96%) !default;
$filter-as:               brightness(0) saturate(100%) invert(40%) sepia(44%) saturate(544%) hue-rotate(197deg) brightness(97%) contrast(92%) !default;

// filter placeholders to allow interpolation in _buttons.scss
%filter-primary {
  filter: $filter-primary;
}

%filter-secondary {
  filter: $filter-secondary;
}

%filter-primary-on-dark {
  filter: $filter-primary-on-dark;
}

%filter-success {
  filter: $filter-success;
}

%filter-info {
  filter: $filter-info;
}

%filter-warning {
  filter: $filter-warning;
}

%filter-warning-on-dark {
  filter: $filter-warning-on-dark;
}


%filter-danger {
  filter: $filter-danger;
}

%filter-danger-on-dark {
  filter: $filter-danger-on-dark;
}

%filter-light {
  filter: $filter-light;
}

%filter-dark {
  filter: $filter-dark;
}

%filter-mid {
  filter: $filter-mid;
}

%filter-white {
  filter: $filter-white;
}

%filter-black {
  filter: $filter-black;
}

%filter-highlight {
  filter: $filter-highlight;
}

%filter-border {
  filter: $filter-border;
}

%filter-tips {
  filter: $filter-tips;
}

%filter-brand {
  filter: $filter-brand;
}

%filter-od-your-primary {
  filter: $filter-od-your-primary;
}

%filter-od-your-comparison {
  filter: $filter-od-your-comparison;
}

%filter-wip {
  filter: $filter-wip;
}

%filter-as-higher {
  filter: $filter-as-higher;
}
%filter-as-medium {
  filter: $filter-as-medium;
}
%filter-as-lower {
  filter: $filter-as-lower;
}
%filter-aa {
  filter: $filter-aa;
}
%filter-taa {
  filter: $filter-taa;
}
%filter-as {
  filter: $filter-as;
}

$filter-colors: () !default;
$filter-colors: map-merge(
  (
    "primary":          $filter-primary,
    "secondary":        $filter-secondary,
    "success":          $filter-success,
    "success-on-dark":  $filter-success-on-dark,
    "info":             $filter-info,
    "warning":          $filter-warning,
    "warning-on-dark":  $filter-warning-on-dark,
    "danger":           $filter-danger,
    "danger-on-dark":   $filter-danger-on-dark,
    "light":            $filter-light,
    "dark":             $filter-dark,
    "mid":              $filter-mid,
    "primary-on-dark":  $filter-primary-on-dark,
    "white":            $filter-white,
    "black":            $filter-black,
    "highlight":        $filter-highlight,
    "border":           $filter-border,
    "as-higher":        $filter-as-higher,
    "as-medium":        $filter-as-medium,
    "as-lower":         $filter-as-lower,
    "brand":           $filter-brand,
    "od-your-primary": $filter-od-your-primary,
    "od-your-comparison": $filter-od-your-comparison,
    "wip":              $filter-wip,
    "aa":               $filter-aa,
    "taa":              $filter-taa,
    "as":               $filter-as
  ),
  $filter-colors
);


// CHART SERIES

$chart-series-0: $primary;
$chart-series-1: $gray-400;
$chart-series-2: $gray-900;

// NAV PILLS 

$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $dark;


$custom-control-indicator-checked-bg: $success-on-dark;