
// tfoot Styling

tfoot {
  font-weight: $font-weight-bold;
  border-bottom: (2 * $table-border-width) solid $table-border-color;
  border-top:(2 * $table-border-width) solid $table-border-color;
}


// tabular numbers

td.text-right {
  font-variant-numeric: tabular-nums;
}

// comparison tables

tr.comparison-table {
  border-top: 2px solid $table-border-color;
}

th.series1 {
  border-left: 2px solid $table-border-color;
  background-color: $chart-series-0;
  color: $white;
}

th.series2 {
  border-left: 2px solid $table-border-color;
  border-right: 2px solid $table-border-color;
  background-color: $chart-series-1;
  color: $gray-900;
}


table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: $body-color;
}


.table th, .table td {
  vertical-align: middle;
  display: table-cell;
}
