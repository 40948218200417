/// BIG UNDERLINE

.big-underline {
  text-decoration-line: underline;
  text-decoration-thickness: 0.4em;
  text-decoration-color: transparent;
  text-underline-offset: 0em;
  transition: text-decoration-color 300ms, text-underline-offset 300ms;
  &:hover {
    text-decoration-line: underline;
    text-decoration-thickness: 0.4em;
    text-decoration-color: $primary;
    text-underline-offset: 0.4em;
    transition: text-decoration-color 300ms, text-underline-offset 300ms;
  }
}

.active .big-underline {
  text-decoration-line: underline;
  text-decoration-thickness: 0.4em;
  text-decoration-color: $primary;
  text-underline-offset: 0.4em;
  transition: text-decoration-color 300ms, text-underline-offset 300ms;
}