/* Gallery container */
.step-container {
  width: 100%;
  overflow: hidden;
}

/* Gallery wrapper for horizontal view */
.step-wrapper-horizontal {
  display: flex;
  transition: transform 0.3s ease;
}

/* Gallery wrapper for vertical view */
.step-wrapper-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Gallery items */
.step-content {
  width: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* Show All button */
#showAllBtn {
  display: block;
  margin: 10px auto;
}