$indicator-size: 24px;

.step-indicator {

    counter-reset: step-counter;
    list-style-type: none;
    position: relative;
    margin-left:0;
    padding-left:0;

    &:before {
      content: "";
      height: 100%;
      width:0;
      border-left: 1px dashed $gray-300;
      position: absolute;
      top: 0;
      bottom:0;
      left: calc($indicator-size / 2);
    }
    li {
      counter-increment: step-counter;
      position: relative;
      list-style-type: none;
      padding-bottom: 1.5rem;
      @include font-size($small-font-size);
      line-height: $indicator-size;
      padding-left:$indicator-size + 10px;
      &::before {
        content: counter(step-counter);
        color: $white;
        font-size: 1rem;
        position: absolute;
        left: 0;
        line-height: $indicator-size;
        width: $indicator-size;
        height: $indicator-size;
        top: 0;
        background: $gray-400;
        text-align: center;
      }
      &:last-child {
        padding-bottom:0;
        background: $white;
      }
      &.active {
        font-weight: bold;
        &:before {
          background: $gray-900;
          font-weight: normal;
        }
      }
      &.link {
        &:before {
          display:none;
        }
        a, a:visited {
          text-decoration: none;
          &::before {
            content: counter(step-counter);
            color: $white;
            font-size: 1rem;
            position: absolute;
            left: 0;
            line-height: $indicator-size;
            width: $indicator-size;
            height: $indicator-size;
            top: 0;
            background: $teal;
            text-align: center;
          }
        }
      }
      &.done {
        color: $success;
        &:before {
          background: url(#{$image-path}icons/check.svg) center center no-repeat $success-on-dark;
          background-size: 12px;

          content: "";

        }
        &.link {
          &:before {
            display:none;
          }
          a, a:visited {
            color: $success;
            &:before {
              background: url(#{$image-path}icons/check.svg) center center no-repeat $success-on-dark;
              background-size: 12px;
              content: "";
              transition: background-color 0.3s ease;
            }
            &:hover, &:focus {
              color: $gray-900;
              &:before {
                background-color: $gray-900;
                transition: background-color 0.3s ease;
              }
            }
          }
        }
      }

    }
    &.larger {
      li {
        @include font-size($font-size-base);
      }
    }

}
