.image-with-icon {
  position:relative;
  display:flex;
  .image {
    width: 80%;
    height: auto;
  }
  .icon {
    position:absolute;
    top:50%;
    right:0;
    width: 50%;
    height: auto;
    transform:translateY(-50%);
  }
}
