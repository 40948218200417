.filter-list {
  margin-bottom: 0;
  li { margin-bottom: 1rem;
    a, a:visited {
      display: block;
      padding: 0.5rem;
      text-decoration: none;
      color: $gray-600;
      transition: background-color 0.3s ease, color 0.3s ease;
      img {
        opacity: 60%;
        transition: opacity 0.3s ease;
      }
      span{
        display:inline-block;
      }
      &:hover, &:focus {
        color: $gray-900;
        background: $gray-100;
        transition: background-color 0.3s ease, color 0.3s ease;
        img {
          opacity: 100%;
          transition: opacity 0.3s ease;
        }
      }
    }
    &.active {
      a, a:visited {
        color: $gray-900;
        background: $gray-100;
        position:relative;
        padding-right: 2.3rem;
        img {
          opacity: 100%;
        }
        span {
          width: 70%;
        }
        &:hover, &:focus {
          background: $gray-200;
          &:after {
            content: "";
            display: block;
            width:24px;
            height: 24px;
            position: absolute;
            bottom: 0.5rem;
            right: 0.5rem;
            background: url(#{$image-path}icons/delete.svg) center center no-repeat;
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")){
  .filter-list {
    li {
      display: inline-block;
    }
  }
}
