// Adds responsive utility sizing classes e.g. w-lg-75 and also adds the same utility classes for max width and height

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h, max-width: mw, max-height: mh) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}


.square-80 {
  width:80px;
  height:80px;
}

.square-180 {
  width:180px;
  height:180px;
}