// Alert styles see: http://taadesign.co.uk/components/alerts

//// ALERTS restyle to make accessibile

.alert {
  border-top-width: $border-thick;
  border-bottom-width: $border-thick;
  border-radius: 0px;
  border-left: none;
  border-right: none;
  padding-left:0;
  padding-right:0;
}

@mixin alert-variant-beta($color, $name) {
  $icon-path: "#{$image-path}icons/info.svg";
  @if $name == 'danger' or $name == 'info' or $name == 'success' or $name == 'warning' or $name == 'tips' or $name == 'wip' {
    $icon-path: "#{$image-path}icons/#{$name}.svg";
  }
  color: $color;
  background: none;
  border-color: $color;
  padding-left: 2.5rem;
  &:before {
    content: "";
    display: inline-block;
    background: url($icon-path) center center no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top:$alert-padding-y;
    left:0;
    @extend %filter-#{$name} !optional;
  }

  hr {
    border-top-color: darken($color, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant-beta($value, $color);
  }
}


/// alternative style: block alerts

@mixin alert-variant-block($color, $name) {
  $icon-path: "#{$image-path}icons/info.svg";
  @if $name == 'danger' or $name == 'info' or $name == 'success' or $name == 'warning' or $name == 'tips' or $name == 'wip' {
    $icon-path: "#{$image-path}icons/#{$name}.svg";
  }
  color: $white;

  background: $color;
  border-color: $color;
  padding:0.5rem 2.5rem;
  a, a:visited {
    color: $white;
  }
  &:before {
    content: "";
    display: inline-block;
    background: url($icon-path) center center no-repeat;
    background-size: contain;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top:0.75rem;
    left:1rem;


      @extend %filter-white;

  }

  hr {
    border-top-color: darken($color, 5%);
  }

  .alert-link {
    color: $white;
  }
  .close {
    padding:0.5rem;
    padding-right: 1rem;
  }
}

@each $color, $value in $theme-colors {
  .alert-block-#{$color} {
    @include alert-variant-block($value, $color);
  }
}
