.aa-link-box {
  position:relative;
  padding-top: 4.5rem !important;
  &:after {
    content: "";
    display:block;
    position:absolute;
    top:0;
    @extend .mt-4;
    @extend .mr-4;
    right:0;
    width: 1.5rem;
    height: 1.5rem;
    background: url(#{$image-path}icons/external_link.svg) center center no-repeat;
    background-size: contain;
    filter: $filter-primary;
  }
  &:before {
    content: "";
    display:block;
    position:absolute;
    top:0.25rem;
    @extend .mt-3;
    @extend .mr-4;
    right:2.5rem;
    width: (79/1.5*2/16)+rem;
    height: 2rem;
    background: url(#{$image-path}logos/audience-answers.svg) center center no-repeat;
    background-size: contain;
  }
}

.internal-link-box {
  position:relative;
  padding-top: 3rem !important;
  &:after {
    content: "";
    display:block;
    position:absolute;
    top:0;
    @extend .mt-4;
    @extend .mr-4;
    right:0;
    width: 1.5rem;
    height: 1.5rem;
    background: url(#{$image-path}icons/link_arrow.svg) center center no-repeat;
    background-size: contain;
    filter: $filter-primary;
  }
}