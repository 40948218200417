.form-control-madlibs {
  display: inline;
  width: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

// Change positioning of custom control indicator to be centered to the label

.custom-control-label {
  &::before, &::after {
    top: 50%;
    margin-top: calc(-0.45 * $custom-control-indicator-size);
  }

}

.form-control:focus {
  background-color: mix($brand, $white, 25%);
}
