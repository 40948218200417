
//// LINK LIST

.link-list {
  padding-left:0;
  li {
    display:inline-block;
    margin-right: 0.8rem;
    margin-bottom: 1rem !important;
    a, a:visited, .link {
      display:block;
      background-color: $black;
      color: $white;
      padding: 1rem;
      text-decoration:none;
      border: 1px solid $black;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
      box-shadow: $box-shadow-sm;
      &:hover, &:focus {
        background: $yellow;
        border-color: $yellow;
        color: $black;
        transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
        text-decoration: none;
      }
    }
  }
}

.link-list.small {
  li {
    margin-right: 0.3rem;
    margin-bottom: 0.5rem;
    a, a:visited, .link {
      padding: 0.5rem;
    }
  }
}

.link-list-block {
  li {
    display:block;
    margin-right:0;
  }
}

.link-list-tight {
  li {
    a, a:visited, .link {
      padding: 0.7rem;
    }
    margin-bottom: 0.7rem;
  }
}

// Mixin to allow different colour backgrounds to the link list

@mixin link-list-backgrounds($background, $border ) {
li a, li a:visited, li .link{
  background-color: $background;
  border-color: $border;
  color: color-yiq($background);
}


}


@each $color, $value in $theme-colors {
  .link-list-#{$color} {
    @include link-list-backgrounds($value,darken($value, 7.5%) );
  }
}

.link-list a {
  [class^="bg"] {
    transition: all 0.3s ease;
  }
  &:hover {
    [class^="bg"] {
      background-color: $black !important;
      color: $white;
      transition: all 0.3s ease;
    }
  }
  
}
