@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in {  

  animation: 1s ease-out 0s 1 slideInFromLeft;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in { animation: 1s ease-out 0s 1 fadeIn; }

@media (prefers-reduced-motion) {
  .slide-in {
    animation: none;
  }
}