// styles for show hide functionality

.showhide {
  line-height: $headings-line-height;
  .show {
    display: none;
  }
}
.collapsed > .showhide {
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
}


  .showhide {
    display: none;
  }

.js-enabled {
  .showhide {
    display: block;
  }
}
