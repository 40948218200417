body {
  position: relative; // for https://getbootstrap.com/docs/4.4/components/scrollspy/
}

html {
  scroll-behavior: smooth;
}


// Prevent anchor links scrolling behind sticky header (ROK)
* {
  scroll-margin-top: 6rem;
}



.page {
  margin-top:0.6rem;
  border-top: 0.6rem solid $brand;
  @extend .bg-white;
  @extend .shadow-sm;
}


@media screen and (max-width: map-get($grid-breakpoints, "lg")){
  .page {
    margin-top:0;
  }
}


.standard-wrapper {
  @extend .px-md-5;
  @extend .px-3;
  @extend .px-xl-7;
}


// custom positioning for 'refine' card in insights

@include media-breakpoint-up(lg) {
  .refine {
    position: absolute;
    left:0;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    width: 100%;
    flex: 0 0 29%;
    max-width: 29%;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.video iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
