.map-number-marker {
  width: 2rem !important;
  height: 2rem !important;
  margin-left: -1rem;
  margin-top: -1rem;
  border-radius: 18px;
  border: 2px solid $od-your-primary;
  text-align: center;
  color: $dark;
  background-color: $od-your-primary;
  font-size: 1rem;
  font-weight: bold;
  line-height:1rem;
  padding-top: 0.3rem;
}

.leaflet-popup-content-wrapper {
  background: $white;
  color: $dark;
  border-radius: 4px;
}

.leaflet-popup-tip {
  background: $white;
}



// honeypot for contact form
.honeypot {
  position: absolute;
  left: -9999px;
}